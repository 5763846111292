<template>
  <section class="board-section w-ba p-2">
    <div class="container mx-auto mb-5" style="max-width: 1300px;">
      <div class="real-time-board">
        <div class="realtime-nav">
          <button
            id="withdraw"
            :class="active == 'withdraw' ? 'active' : ''"
            @click="setActiveTab('withdraw')"
          >
            <font-awesome-icon
              :class="active == 'withdraw' ? 'active-color' : ''"
              icon="fa-solid fa-trophy"
            />
            {{ $t('withdraw') }}
          </button>
          <button
            id="deposit"
            :class="active == 'deposit' ? 'active' : ''"
            @click="setActiveTab('deposit')"
          >
            <font-awesome-icon
              :class="active == 'deposit' ? 'active-color' : ''"
              icon="fa-solid fa-crown"
            />
            {{ $t('deposit') }}
          </button>

          <div class="content mt-2">
            <div
              id="new_withdraw"
              class="rolling-realtime"
              v-if="active == 'withdraw'"
            >
              <ul class="">
                <li class="flex transaction-header-list">
                  <div>#</div>
                  <div>{{ $t('amount') }}</div>
                  <div>{{ $t('status') }}</div>
                  <div>{{ $t('date') }}</div>
                </li>

                <li
                  v-for="(data, index) in withdraw_data.list"
                  :key="index"
                  class="flex items-start justify-center"
                >
                  <div class="#">
                    <span>
                      {{
                        index +
                        1 +
                        (this.withdraw_data.current_page - 1) *
                          this.withdraw_data.per_page
                      }}
                    </span>
                  </div>

                  <div class="amount justify-start">
                    <span>{{ numberString(data.amount) }}</span>
                  </div>
                  <div class="status">
                    <span
                      :class="`${
                        data.status == 'complete'
                          ? 'complete'
                          : data.status == 'cancel'
                          ? 'cancel'
                          : ''
                      }`"
                    >
                      {{ $t(data.status) }}
                    </span>
                  </div>
                  <div class="date">
                    <span>{{ data.created_at }}</span>
                  </div>
                </li>
                <li
                  class="text-center pt-24 block"
                  v-if="withdraw_data.list.length <= 0"
                >
                  {{ $t('there is no history') }}
                </li>
              </ul>
            </div>

            <div
              id="new_deposit"
              class="rolling-realtime"
              v-if="active == 'deposit'"
            >
              <ul>
                <li class="flex transaction-header-list">
                  <div>#</div>
                  <div>{{ $t('amount') }}</div>
                  <div>{{ $t('status') }}</div>
                  <div>{{ $t('date') }}</div>
                </li>
                <li
                  v-for="(data, index) in deposit_data.list"
                  :key="index"
                  class="flex items-start justify-center"
                >
                  <div class="user">
                    <span>
                      {{
                        index +
                        1 +
                        (this.deposit_data.current_page - 1) *
                          this.deposit_data.per_page
                      }}
                    </span>
                  </div>
                  <div class="amount justify-start">
                    <span>{{ numberString(data.amount) }}</span>
                  </div>
                  <div class="status">
                    <span
                      :class="`${
                        data.status == 'complete'
                          ? 'complete'
                          : data.status == 'cancel'
                          ? 'cancel'
                          : ''
                      }`"
                    >
                      {{ $t(data.status) }}
                    </span>
                  </div>
                  <div class="date">
                    <span>{{ data.created_at }}</span>
                  </div>
                </li>
                <li
                  class="text-center pt-24 block"
                  v-if="deposit_data.list.length <= 0"
                >
                  {{ $t('there is no history') }}
                </li>
              </ul>
            </div>
            <div class="mt-10" v-if="active == 'withdraw'">
              <Pagination
                :data="withdraw_data"
                @emitPage="initList2"
                v-if="withdraw_data.list.length > 0"
              />
            </div>
            <div class="mt-5" v-if="active == 'deposit'">
              <Pagination
                :data="deposit_data"
                @emitPage="initList"
                v-if="deposit_data.list.length > 0"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mx-auto customer-container">
      <div class="row dflex-ac-jc align-items-start">
        <a class="customer-btn w-ba">
          <div class="g-panel w-ba">
            <div class="g-logo dflex-ac-jc w-ba">
              <img class="icon-img" src="@/assets/img/phone.png" />
            </div>
            <div class="g-info">
              <span class="g-name">전화문의</span>
              <span class="en-text"></span>
            </div>
            <div class="glass"></div>
          </div>
        </a>
        <a class="customer-btn w-ba">
          <div class="g-panel w-ba">
            <div class="g-logo dflex-ac-jc w-ba">
              <img class="icon-img" src="@/assets/img/telegram.png" />
            </div>
            <div class="g-info">
              <span class="g-name">텔레그램</span>
              <span class="en-text"></span>
            </div>
            <div class="glass"></div>
          </div>
        </a>
        <a class="customer-btn w-ba">
          <div class="g-panel w-ba">
            <div class="g-logo dflex-ac-jc w-ba">
              <img class="icon-img" src="@/assets/img/kakao.png" />
            </div>
            <div class="g-info">
              <span class="g-name">카톡</span>
              <span class="en-text"></span>
            </div>
            <div class="glass"></div>
          </div>
        </a>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import number from '@/mixins/number'
import Pagination from '@/components/Pagination'
import Swal from 'sweetalert2'
export default {
  name: 'Real Time Board',
  components: { Pagination },
  data() {
    return {
      active: 'withdraw',
      loading: false,
      deposit_data: {
        list: [],
        links: [],
        current_page: 1,
        per_page: 1,
        last_page: 1,
        total: 0,
      },
      withdraw_data: {
        list: [],
        links: [],
        current_page: 1,
        per_page: 1,
        last_page: 1,
        total: 0,
      },
    }
  },
  mixins: [number],
  computed: {
    ...mapGetters('auth', ['authenticated', 'profile', 'pusher_channel']),
  },
  watch: {
    'authenticated' (loggedIn) {
      if(loggedIn) {
        this.initList(1)
        this.initList2(1)
      } 
    }
  },
  methods: {
    ...mapActions('auth', {
      authGetProfile: 'getProfile',
    }),
    ...mapActions('transaction', {
      depositList: 'getDepositList',
      withdrawList: 'getWithdrawList',
    }),
    ...mapActions('realTimeWithdraw', {
      getWithdraw: 'getList',
    }),
    setActiveTab(tab) {
      this.active = tab
    },
    async initList(p) {
      var pl = {
        page_no: p ? p : 1,
        limit: 10,
        type: 'deposit',
      }

      const data = await this.depositList(pl)
      this.deposit_data.list = data.data
      this.deposit_data.links = data.links
      this.deposit_data.current_page = data.current_page
      this.deposit_data.per_page = data.per_page
      this.deposit_data.last_page = data.last_page
    },
    async initList2(p) {
      var pl = {
        page_no: p ? p : 1,
        limit: 10,
        type: 'withdraw',
      }

      const data = await this.withdrawList(pl)
      this.withdraw_data.list = data.data
      this.withdraw_data.links = data.links
      this.withdraw_data.current_page = data.current_page
      this.withdraw_data.per_page = data.per_page
      this.withdraw_data.last_page = data.last_page
    },
  },
  async mounted() {
    if (this.authenticated) { 
        this.initList(1)
        this.initList2(1)
      const pusher = new Pusher(process.env.VUE_APP_PUSHER_APP_KEY, {
        cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
        useTLS: true,
      })

      const channel = pusher.subscribe(this.pusher_channel)
      // console.log(this.pusher_channel)
      channel.bind('deposit', (e) => {getWithdrawList
        console.log(e)
        Swal.fire({
          html: e.message,
          type: 'success',
          icon: 'info',
          timer: 1000,
        })

        if (
          e.message == 'Transaction successful.' ||
          e.message == 'Transaction canceled.'
        ) {
          this.authGetProfile()
          this.initList(1)
        }
      })
      channel.bind('withdraw', (e) => {
        console.log(e)
        Swal.fire({
          html: e.message,
          type: 'success',
          icon: 'info',
          timer: 1000,
        })

        if (
          e.message == 'Transaction successful.' ||
          e.message == 'Transaction canceled.'
        ) {
          this.authGetProfile()
          this.initList2(1)
        }
      })
    }
  },
}
</script>

<style></style>
