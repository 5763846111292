<template>
  <div class="modal" id="openRegisterModal">
    <div class="modal-content modal-panel relative">
      <div class="modal-header">
        <button
          class="modal-close-btn w-ba"
          @click="closePopup('openRegisterModal');reset()"
        ></button>
        <div class="modal-banner flex flex-col justify-center items-center">
          <a href="javascript:void(0)" class="modal-logo flex justify-center">
            <img src="@/assets/img/dream.png" alt="Dream Logo" />
          </a>
          <div class="modal-title">
            <div class="modal-icon dflex-ac-jc">
              <font-awesome-icon class="" icon="fa-solid fa-door-open" />
            </div>
            <div class="title-panel text-center">
              <span class="title">로그인</span>
              <span class="sub w-mob-title"
                >언제나 노력하는 CASINO 되겠습니다!</span
              >
            </div>
          </div>
        </div>
        <div class="modal-head-panel dflex-ac-jc">
          <span class="modal-title-mob hidden">WELCOME TO CASINO</span>
        </div>
      </div>
      <div class="modal-body mt-5">
        <h2>{{ $t("register") }}</h2>
        <div class="form-container pt-5">
          <div class="form-group">
            <div class="infos flex flex-col">
              <label for="name" class="block mb-1">{{ $t("name") }}</label>
              <div class="input-container">
                <input
                  type="text"
                  :placeholder="$t('name')"
                  name="name"
                  v-model="name"
                  :class="{
                    'is-invalid': submitted && v$.name.$error,
                  }"
                />
              </div>
            </div>
            <div
              v-for="(item, index) in v$.name.$errors"
              :key="index"
              class="invalid-feedback block uppercase pt-3"
              style=""
            >
              <span v-if="item.$message">{{ $t(item.$message) }}</span>
            </div>
          </div>
          <div class="form-group">
            <div class="infos flex flex-col">
              <label for="username" class="block mb-1">{{
                $t("username")
              }}</label>
              <div class="input-container">
                <input
                  type="text"
                  :placeholder="$t('username')"
                  v-model="username"
                  :class="{
                    'is-invalid': submitted && v$.username.$error,
                  }"
                />
              </div>
            </div>
            <div
              v-for="(item, index) in v$.username.$errors"
              :key="index"
              class="invalid-feedback block uppercase pt-3"
            >
              <span v-if="item.$message">{{ $t(item.$message) }}</span>
            </div>
          </div>
          <div class="form-group">
            <div class="infos flex flex-col">
              <label for="password" class="block mb-1">{{
                $t("password")
              }}</label>
              <div class="input-container">
                <div class="flex relative w-full">
                  <input
                    type="password"
                    :placeholder="$t('password')"
                    v-model="password"
                    :class="{
                      'is-invalid': submitted && v$.password.$error,
                    }"
                    v-if="!showPassword"
                  />
                  <input
                    type="text"
                    :placeholder="$t('password')"
                    v-model="password"
                    :class="{
                      'is-invalid': submitted && v$.password.$error,
                    }"
                    v-if="showPassword"
                  />
                  <font-awesome-icon
                    style="color: #d2b270"
                    class="absolute right-2 top-4"
                    :icon="
                      !showPassword
                        ? 'fa-regular fa-eye'
                        : 'fa-regular fa-eye-slash'
                    "
                    @click="togglePassword"
                  ></font-awesome-icon>
                </div>
              </div>
            </div>
            <div
              v-for="(item, index) in v$.password.$errors"
              :key="index"
              class="invalid-feedback block uppercase pt-3"
            >
              <span v-if="item.$message">{{ $t(item.$message) }}</span>
            </div>
          </div>
          <div class="form-group">
            <div class="infos flex flex-col">
              <label for="confirm password" class="block mb-1">{{
                $t("confirm password")
              }}</label>
              <div class="input-container">
                <div class="flex relative w-full">
                  <input
                    type="password"
                    :placeholder="$t('confirm password')"
                    v-model="confirm_password"
                    :class="{
                      'is-invalid': submitted && v$.confirm_password.$error,
                    }"
                    v-if="!showConfirmPassword"
                  />
                  <input
                    type="text"
                    :placeholder="$t('confirm password')"
                    v-model="confirm_password"
                    :class="{
                      'is-invalid': submitted && v$.confirm_password.$error,
                    }"
                    v-if="showConfirmPassword"
                  />
                  <font-awesome-icon
                    style="color: #d2b270"
                    class="absolute right-2 top-4"
                    :icon="
                      !showConfirmPassword
                        ? 'fa-regular fa-eye'
                        : 'fa-regular fa-eye-slash'
                    "
                    @click="toggleConfirmPassword"
                  ></font-awesome-icon>
                </div>
              </div>
            </div>
            <div
              v-for="(item, index) in v$.confirm_password.$errors"
              :key="index"
              class="invalid-feedback block uppercase pt-3"
            >
              <span v-if="item.$message">{{ $t(item.$message) }}</span>
            </div>
          </div>
          <div class="form-group">
            <div class="infos flex flex-col">
              <label for="confirm pin" class="block mb-1">{{
                $t("confirm pin")
              }}</label>
              <div class="input-container">
                <div class="flex relative w-full">
                  <input
                    type="password"
                    :placeholder="$t('confirm pin')"
                    v-model="pin"
                    :class="{
                      'is-invalid': submitted && v$.pin.$error,
                    }"
                    v-if="!showConfirmPassword"
                  />
                  <input
                    type="text"
                    :placeholder="$t('confirm pin')"
                    v-model="pin"
                    :class="{
                      'is-invalid': submitted && v$.pin.$error,
                    }"
                    v-if="showConfirmPassword"
                  />
                </div>
              </div>
            </div>
            <div
              v-for="(item, index) in v$.confirm_password.$errors"
              :key="index"
              class="invalid-feedback block uppercase pt-3"
            >
              <span v-if="item.$message">{{ $t(item.$message) }}</span>
            </div>
          </div>
          <div class="form-group">
            <div class="infos flex flex-col">
              <label for="referral code" class="block mb-1">{{
                $t('referral code')
              }}</label>
              <div class="input-container">
                <input
                  type="text"
                  :placeholder="$t('referral code')"
                  v-model="referral_code"
                  :class="{
                    'is-invalid': submitted && v$.referral_code.$error,
                  }"
                />
              </div>
            </div>
            <div
              v-for="(item, index) in v$.referral_code.$errors"
              :key="index"
              class="invalid-feedback block uppercase pt-3"
            >
              <span v-if="item.$message">{{ $t(item.$message) }}</span>
            </div>
          </div>
        </div>
        <div class="form-footer dflex-ac-jc">
          <button type="button" class="btn-yellow join-link" @click="submit">
            {{ $t("submit") }}
          </button>
          <button
            type="button"
            class="btn-red login-btn login-link"
            @click="
              closePopup('openRegisterModal');
              openPopup('openLoginModal');
            "
          >
            {{ $t("login") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ui from "@/mixins/ui";
import { required, minLength, sameAs, helpers } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import { mapActions } from "vuex";
import Swal from "sweetalert2";
export default {
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      submitted: false,
      name: null,
      username: null,
      password: null,
      confirm_password: null,
      pin: null,
      referral_code: null,
      showPassword: false,
      showConfirmPassword: false,
    };
  },
  validations() {
    return {
      name: {
        required: helpers.withMessage("required", required),
        minLength: helpers.withMessage(
          "should be atleast 4 characters",
          minLength(4)
        ),
      },
      username: {
        required: helpers.withMessage("required", required),
        minLength: helpers.withMessage(
          "should be atleast 4 characters",
          minLength(4)
        ),
      },
      password: {
        required: helpers.withMessage("required", required),
        minLength: helpers.withMessage(
          "should be atleast 4 characters",
          minLength(4)
        ),
      },
      confirm_password: {
        required: helpers.withMessage("required", required),
        sameAs: helpers.withMessage(
          `password doesn't match`,
          sameAs(this.password)
        ),
      },
      pin: {
        required: helpers.withMessage("required", required),
         minLength: helpers.withMessage(
          "should be atleast 4 characters",
          minLength(4)
        ),
      },
      referral_code: {
        // required: helpers.withMessage("required", required),
      },
    };
  },
  mixins: [ui],
  methods: {
    ...mapActions("auth", {
      authRegisterUser: "registerUser",
      authGetProfile: "getProfile",
    }),
    togglePassword() {
      this.showPassword = !this.showPassword;
    },
    toggleConfirmPassword() {
      this.showConfirmPassword = !this.showConfirmPassword;
    },
    async submit() {
      this.submitted = true;
      this.v$.$touch();
      if (this.v$.$invalid || this.auth_limit) {
        return;
      } else {
        var fd = new FormData();
        fd.append("name", this.name);
        fd.append("username", this.username);
        fd.append("password", this.password);
        fd.append("confirm_password", this.confirm_password);
        fd.append("pin", this.pin);
        if(this.referral_code) fd.append("referral_code", this.referral_code);

        const res = await this.authRegisterUser(fd);

        if (res.success) {
          this.closePopup("openRegisterModal");
          this.reset();
          Swal.fire({
            icon: "success",
            title: this.$t("successfully registered"),
            confirmButtonText: this.$t("OK"),
          });
        } else {
          Swal.fire({
            icon: "error",
            title: this.$t("registration failed"),
            html: this.$t(res.message),
            confirmButtonText: this.$t("OK"),
          });
        }
      }
    },
    reset() {
      this.name = null;
      this.username = null;
      this.password = null;
      this.confirm_password = null;
      this.referral_code = null;
      this.submitted = false;
    },
  },
};
</script>
