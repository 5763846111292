<template>
  <header class="header-section w-ba">
    <div class="dflex-ac-jc h-full lg:px-3">
      <ul
        class="bs-ul main-menu sidebar-left dflex-ac-jc"
        style="justify-content: space-around;"
      >
        <li class="flex items-center justify-center h-full ml-3">
          <div class="dropdown" id="locale-dd">
            <button class="dropbtn">
              <img :src="current_locale_flag" alt="korea" />
            </button>
            <div class="dropdown-content">
              <a
                href="#"
                @click.prevent="changeLocale('ko')"
                @touchend="hide()"
                style="height: 30px; width: 100%;"
              >
                <img
                  :src="require(`@/assets/img/flags/korea.jpg`)"
                  alt="korea"
                />
                <span>KO</span>
              </a>
              <a
                href="#"
                @click.prevent="changeLocale('en')"
                @touchend="hide()"
                style="height: 30px;"
              >
                <img :src="require(`@/assets/img/flags/us.jpg`)" alt="us" />
                <span>EN</span>
              </a>
            </div>
          </div>
        </li>
        <li @click="goToDeposit">
          <a href="javascript:void(0);" class="deposit-link dflex-ac-jc w-ba">
            <font-awesome-icon class="mr-2 fa" icon="fa-solid fa-piggy-bank" />
            <!-- 입금신청 -->
            {{ $t('deposit application') }}
          </a>
        </li>
        <li @click="goToWithdraw">
          <a href="javascript:void(0);" class="withdraw-link dflex-ac-jc w-ba">
            <font-awesome-icon class="mr-2 fa" icon="fa-solid fa-vault" />
            <!-- 출금신청 -->
            {{ $t('withdraw application') }}
          </a>
        </li>

        <!--li><a href="javascript:void(0);" class="comp-link dflex-ac-jc w-ba"><i class="fa-solid fa-money-bill-1"></i> 콤프전환</a></!li-->
        <li @click="goToCustomerService" class="relative">
          <a
            href="javascript:void(0);"
            class="mypage-link dflex-ac-jc w-ba"
            data-target=".item-qna"
          >
            <font-awesome-icon
              icon="fa-solid fa-comment-dots"
              class="mr-2 fa"
            />
            <!-- 고객센터 -->
            {{ $t('customer service') }}
          </a>
          <span class="notification-count" v-if="profile.ticket.unread > 0">
            {{ profile.ticket.unread }}
            <!-- 0 -->
          </span>
        </li>
        <li @click="goToNotice">
          <a href="javascript:void(0);" class="notice-link dflex-ac-jc w-ba">
            <font-awesome-icon icon="fa-solid fa-bell" class="mr-2 fa" />
            <!-- 공지사항 -->
            {{ $t('notice') }}
          </a>
        </li>
        <li @click="goToCoupon">
          <a href="javascript:void(0);" class="coupon-link dflex-ac-jc w-ba">
            <font-awesome-icon icon="fa-solid fa-ticket" class="mr-2 fa" />
            <!-- 공지사항 -->
            {{ $t('coupon') }}
          </a>
        </li>
        <li @click="switchPoints" class="flex items-center cursor-pointer">
          <a href="javascript:void(0);" class="point-link dflex-ac-jc w-ba ">
            <span class="" v-if="!authenticated"> 
              <font-awesome-icon
                icon="fa-solid fa-sack-dollar"
                class="mr-2 fa"
              />
              {{ $t('switch points') }}
            </span>

            <span class="mr-3" v-if="authenticated">
              <font-awesome-icon icon="fa-solid fa-sack-dollar" class="mr-1" />
              {{ myBalance }}
              <span
                  class="game-status text-warning" style="text-transform: uppercase"
                  v-if="profile.transfer_in_game || this.$store.state.popups.length > 0"
                  >In-Game</span
              >
            </span>
            <span class="" v-if="authenticated">
              <font-awesome-icon icon="fa-solid fa-coins" class="mr-1" />
              {{ numberString(Math.floor(profile.points)) }}
            </span>
          </a>
        </li>
        <li @click="exitGame()" class="flex items-center cursor-pointer" v-if="profile.transfer_in_game">
          <a href="javascript:void(0);" class="point-link dflex-ac-jc w-ba ">
            Exit Game &nbsp;
            <img
                  v-if="this.$store.state.game.exiting_game"
                  src="@/assets/img/small-loader.svg"
                  alt="" />
          </a>
        </li>
    
      </ul>

      <div class="bal-container ml-auto h-full xl:flex items-center">
        <ul
          class="bs-ul main-menu sidebar-right dflex-ac-jc"
          v-if="authenticated"
        >
          <li @click="openPopup('profilePopUp')" class="mr-2 md:mr-0">
            <a href="javascript:void(0);" class="profile-link dflex-ac-jc w-ba">
              <font-awesome-icon
                class="text-md lg:text-md header-icon mr-2"
                icon="fa-solid fa-user"
              />
              <span>{{ $t('my information') }}</span>
            </a>
          </li>
          <li @click="logout">
            <a href="javascript:void(0);" class="logout-link dflex-ac-jc w-ba">
              <font-awesome-icon
                class="text-md lg:text-md header-icon mr-2"
                icon="fa-solid fa-right-from-bracket"
              />
              <span>{{ $t('logout') }}</span>
            </a>
          </li>
        </ul>
      
        <div class="before-login active">
          <div class="desktop" v-if="!authenticated">
            <div class="dflex-ac-jc">
              <button
                type="button"
                class="btn-yellow login-link login-btn"
                @click="openPopup('openLoginModal')"
              >
                로그인
              </button>
              <button
                type="button"
                class="btn-red join-link"
                @click="openPopup('openRegisterModal')"
              >
                회원가입
              </button>
            </div>
          </div>

          <div class="mobile" v-if="!authenticated">
            <div class="dflex-ac-jc">
              <button
                type="button"
                class="btn-yellow login-link login-btn"
                @click="openPopup('openLoginModal')"
              >
                <font-awesome-icon
                  class=""
                  icon="fa-solid fa-right-to-bracket"
                />
                로그인
              </button>
              <button
                type="button"
                class="btn-red join-link"
                @click="openPopup('openRegisterModal')"
              >
                <font-awesome-icon class="" icon="fa-solid fa-user-plus" />
                회원가입
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
  <!-- <div v-if="authenticated"> -->
  <div v-if="authenticated">
    <TicketPopUp />
    <EventAnnouncement />
    <WithdrawPopup />
    <DepositPopup />
    <CouponPopUp />
    <ConvertPointsPopUp />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { required, helpers } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'
import Swal from 'sweetalert2'
import Banner from '@/components/Banner'
import ui from '@/mixins/ui'
import number from '@/mixins/number'
import TicketPopUp from '@/components/TicketPopUp.vue'
import EventAnnouncement from '@/components/EventAnnouncement.vue'
import DepositPopup from '@/components/DepositPopup'
import WithdrawPopup from '@/components/WithdrawPopup'
import CouponPopUp from '@/components/CouponPopUp.vue'
import ConvertPointsPopUp from '@/components/ConvertPointsPopUp.vue'

export default {
  setup() {
    return { v$: useVuelidate() }
  },

  data() {
    return {
      submitted: false,
      username: null,
      password: null,
      loading: false,
    }
  },
  validations() {
    return {
      username: {
        required: helpers.withMessage('required', required),
      },
      password: {
        required: helpers.withMessage('required', required),
      },
    }
  },
  components: {
    Banner,
    TicketPopUp,
    EventAnnouncement,
    WithdrawPopup,
    DepositPopup,
    CouponPopUp,
    ConvertPointsPopUp,
  },
  mixins: [ui, number],
  computed: {
    ...mapGetters('auth', ['authenticated', 'profile', 'pusher_channel']),
    current_locale_flag() {
      var flag
      switch (this.$i18n.locale) {
        case 'en':
          flag = require('@/assets/img/flags/us.jpg')
          break
        case 'ko':
          flag = require('@/assets/img/flags/korea.jpg')
          break
      }
      return flag
    },
    myBalance() {
          var balance = null;
            if (process.env.VUE_APP_GAME_LAUNCH_MODE == 'single' 
                && (this.profile.transfer_in_game || this.$store.state.popups.length > 0)
            ) {
                balance = '0';
            } else if (
                process.env.VUE_APP_GAME_LAUNCH_MODE == 'multiple' 
                &&  (this.profile.transfer_in_game || this.$store.state.popups.length > 0)
            ) {
                if (this.$store.state.gameType == 'slot') {
                    balance = '0';
                } else if (this.$store.state.gameType == 'casino') {
                    balance =
                        this.profile.balance > 0
                            ? this.numberString(this.profile.balance)
                            : this.numberString(this.profile.balance);
                }
            } else {
                balance =this.numberString(this.profile.balance)
            }

            return balance;
        },
  },
  methods: {
    ...mapActions('auth', {
      authLogout: 'logoutUser',
      authLogin: 'loginUser',
      authGetProfile: 'getProfile',
    }),
    changeLocale(lang) {
      this.$i18n.locale = lang
      $cookies.set('locale', lang)
      //   this.hide();
    },
    hide() {
      document.querySelector('.dropdown-content')
    },
    checkAuth(mode) {
      if (this.authenticated) this.openPopup(mode)
    },
    async tryToLogIn() {
      this.submitted = true
      this.v$.$touch()
      if (this.v$.$invalid || this.auth_limit) {
        return
      } else {
        var fd = new FormData()
        fd.append('username', this.username)
        fd.append('password', this.password)

        this.loading = true
        const res = await this.authLogin(fd)

        if (res.success) {
          //   this.closePopup("login");
          this.reset()
          this.authGetProfile()
          Swal.fire({
            icon: 'success',
            title: this.$t('successfully logged in'),
            timer: 2000,
          })
          // this.$router.replace({ path: '/' }).catch(() => {});
        } else {
          Swal.fire({
            icon: 'error',
            title: this.$t('invalid credentials'),
            confirmButtonText: this.$t('OK'),
          })
          // this.loading = false;
          // this.error_message = res.message;
          // const expirationDate = new Date();
          // expirationDate.setTime(expirationDate.getTime() + 3 * 60 * 1000)
          // this.auth_attempts.count += 1;
          // this.auth_attempts.expiration = expirationDate;
          // $cookies.set('auth_attempts', {
          //     count: this.auth_attempts.count,
          //     expiration: expirationDate
          // }, expirationDate);
        }
      }
    },

    async logout() {
      await this.authLogout()
    },
    goToDeposit() {
      if (!this.authenticated) {
        this.openPopup('openLoginModal')
      } else {
        this.openPopup('depositPopUp')
      }
    },
    goToWithdraw() {
      if (!this.authenticated) {
        this.openPopup('openLoginModal')
      } else {
        this.openPopup('withdrawPopUp')
      }
    },
    switchPoints() {
      if (!this.authenticated) {
        this.openPopup('openLoginModal')
      } else {
        this.openPopup('convertPopUp')
      }
    },
    goToCustomerService() {
      if (!this.authenticated) {
        this.openPopup('openLoginModal')
      } else {
        this.openPopup('ticketPopUp')
      }
    },
    goToNotice() {
      if (!this.authenticated) {
        this.openPopup('openLoginModal')
      } else {
        this.openPopup('openNotice')
      }
    },
    goToCoupon() {
      if (!this.authenticated) {
        this.openPopup('openLoginModal')
      } else {
        this.openPopup('couponPopUp')
      }
    },
    reset() {
      this.username = ''
      this.password = ''
    }, 
    async exitGame() {
        if (this.loading) {
            return;
        }
        this.$store.commit('game/triggerExiting', true);
        const res1 = await this.$store.dispatch('game/exitMamahot');
        if (res1.status == 200 || res2.status == 200) {
            await this.$store.dispatch('auth/getProfile');
        }
        this.$store.commit('game/triggerExiting', false);
    },
  },
  mounted() {
    if (Boolean(window.PusherChannel)) {
      window.PusherChannel.bind('ticket', (e) => {
        if (
          e.message == 'You have a new ticket.' ||
          e.message == 'You have an unread ticket.'
        ) {
          this.authGetProfile()
        }
        Swal.fire({
          html: e.message,
          type: 'success',
          icon: 'info',
          timer: 1000,
        })
      })
      window.PusherChannel.bind('deposit', (e) => {
        Swal.fire({
          html: e.message,
          type: 'success',
          icon: 'info',
          timer: 1000,
        })

        if (
          e.message == 'Transaction successful.' ||
          e.message == 'Transaction canceled.'
        ) {
          this.authGetProfile()
          this.initList()
        }

        console.log(e)
        this.authGetProfile()
      })
      window.PusherChannel.bind('withdraw', (e) => {
        Swal.fire({
          html: e.message,
          type: 'success',
          icon: 'info',
          timer: 1000,
        })
        if (
          e.message == 'Transaction successful.' ||
          e.message == 'Transaction canceled.'
        ) {
          this.authGetProfile()
          this.initList()
        }

        console.log(e)
        this.authGetProfile()
      })
    }
  },
}
</script>
