<template>
  <div class="modal bg-active custom__popup" v-for="p in popups" :key="p._id" :id="p._id">
    <div class="modal-content modal-panel relative">
      <div class="modal-body mt-5">
        <div><button
          class="modal-close-btn w-ba"
          @click="
            closePopup(p._id);
          "
        ></button></div>
        <div class="content__wrapper" v-html="p.content">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions,mapGetters } from 'vuex';
import ui from '@/mixins/ui';
export default {
    name: 'AuthModal',
    mixins: [ui],
    data() {
        return {
            popups: []
        };
    },
    computed: {
        ...mapGetters('auth', ['authenticated','role'])
    },
    methods: {
        ...mapActions('popup',{
            getPopups: 'getList'
        }),
        async initPopup() {
            const res =  await this.getPopups({});
            if(res.data) {
                this.popups = await res.data
            }
        },
        async resetPopup() {
            const popups = document.querySelectorAll(".custom__popup")
            if(popups) {
                Array.from(popups).forEach((popup)=> {
                    popup.classList.add("bg-active")
                })
            }
            this.popups = []
        }
    },
    watch: {
        authenticated(auth) {
            if(auth) {
                if(this.role == 'player') {
                    this.initPopup();
                }
            } else {
                this.resetPopup();
            }
        }
    },
    mounted() {
        // var vm = this;
        // let modal = document.getElementById('popup');
        // modal.addEventListener('mousedown', function (e) {
        //     e = window.event || e;
        //     if (this === e.target) {
        //         vm.reset();
        //     }
        // });
        if(this.authenticated && this.role == 'player') {
            this.initPopup();
        }
    },
};
</script>
