import { createRouter, createWebHistory } from 'vue-router'
import home from '../views/home.vue'
import store from "@/state/store";
const routes = [
  {
    path: '/',
    name: 'home',
    component: home
  },
  {
    path: '/transaction',
    name: 'transaction',
    component: () =>
      import(/* webpackChunkName: "transaction" */ '../views/transaction.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/games/slot_detail',
    name: 'slot-gamelist',
    component: () => import('../views/slot-game-list/game-list.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/games/casino',
    name: 'casino-gamelist',
    component: () => import('../views/live-casino-list/game-list.vue'),
    meta: { requiresAuth: true },
  },
  	{
		path: "/game-window/:type(casino|slot)",
		name: "game-window",
		component: () => import(/* webpackChunkName: "game-window" */ '../views/GameWindow.vue'),
		meta: { requiresAuth: true },
	},
  {
        path: '/game/:type(casino|slot)',
        name: 'provider-game-list',
        component: () => import(/* webpackChunkName: "provider-game-list" */ '../views/ProviderGameList.vue'),
        meta: { requiresAuth: true }
  },
]


const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
  })
  

router.beforeEach(async (routeTo, routeFrom, next) => {
    const isAuthenticated = store.getters["auth/authenticated"];
    if (isAuthenticated) {
      await store.dispatch("auth/getProfile");
    }
  
    if (routeTo.matched.some((record) => record.meta.requiresAuth)) {
      if (!isAuthenticated) {
        next("/");
      } else {
        next();
      }
    } else if (routeTo.matched.some((record) => record.meta.requiresVisitor)) {
      if (isAuthenticated) {
        next("/");
      } else {
        next();
      }
    } else {
      next();
    }
  });
  



export default router
