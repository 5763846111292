import $api from "../api";

export const actions = {
  getDepositList({ commit, rootGetters, dispatch }, pl) {
    return new Promise(function (resolve) {
      $api
        .get(
          `${rootGetters["auth/role"]}/transactions?${pl.type ? "type=" + pl.type : ""}&${pl.page_no ? "page=" + pl.page_no : ""}${
            pl.limit ? "&limit=" + pl.limit : "&limit=50"
          }`,
          {
            headers: {
              Authorization: rootGetters["auth/bearer_token"],
            },
          }
        )
        .then(function (res) {
          if (res.status == 200) {
            resolve(res.data.data);
          }
        })
        .catch(function (err) {
          if (err.response.status == 401) {
            dispatch("auth/logout", {}, { root: true }).then(() => {});
          }
        });
    });
  },
  getWithdrawList({ commit, rootGetters, dispatch }, pl) {
    return new Promise(function (resolve) {
      $api
        .get(
          `${rootGetters["auth/role"]}/transactions?${pl.type ? "type=" + pl.type : ""}&${pl.page_no ? "page=" + pl.page_no : ""}${
            pl.limit ? "&limit=" + pl.limit : "&limit=50"
          }`,
          {
            headers: {
              Authorization: rootGetters["auth/bearer_token"],
            },
          }
        )
        .then(function (res) {
          if (res.status == 200) {
    
            resolve(res.data.data);
          }
        })
        .catch(function (err) {
          console.log(err, "err");
          if (err.response.status == 401) {
            dispatch("auth/logout", {}, { root: true }).then(() => {});
          }
        });
    });
  },
  deposit({ rootGetters, dispatch }, pl) {
    return new Promise(function (resolve) {
      $api
        .post(`${rootGetters["auth/role"]}/transaction/deposit`, pl, {
          headers: {
            Authorization: rootGetters["auth/bearer_token"],
          },
        })
        .then(function (res) {
          if (res.status == 200) {
            // resolve({
            //     success: true,
            //     message: res.data.message
            // });
            resolve(res);
          }
        })
        .catch(function (err) {
          if (err.response.status == 401) {
            dispatch("auth/logout", {}, { root: true }).then(() => {});
          }
          if (err.response.status == 400) {
            // resolve({
            //     success: false,
            //     message: err.response.data.message
            // });
            resolve(err.response);
          }
          if (err.response.status == 422) {
            resolve(err.response);
          }
        });
    });
  },
  withdraw({ rootGetters, dispatch }, pl) {
    return new Promise(function (resolve) {
      $api
        .post(`${rootGetters["auth/role"]}/transaction/withdraw`, pl, {
          headers: {
            Authorization: rootGetters["auth/bearer_token"],
          },
        })
        .then(function (res) {
          if (res.status == 200) {
            // resolve({
            //     success: true,
            //     message: res.data.message
            // });
            resolve(res);
          }
        })
        .catch(function (err) {
          console.log(err);
          if (err.response.status == 401) {
            dispatch("auth/logout", {}, { root: true }).then(() => {});
          }
          if (err.response.status == 400) {
            // resolve({
            //     success: false,
            //     message: err.response.data.message
            // });
            resolve(err.response);
          }
          if (err.response.status == 422) {
            resolve(err.response);
          }
        });
    });
  },
  getBank({ rootGetters, dispatch }, pl) {
    return new Promise(function (resolve) {
      $api
        .get(`${rootGetters["auth/role"]}/transaction/bank`, {
          headers: {
            Authorization: rootGetters["auth/bearer_token"],
          },
        })
        .then(function (res) {
          if (res.status == 200) {
            resolve(res.data.data);
          }
        })
        .catch(function (err) {
          console.log(err);
          if (err.response.status == 401) {
            dispatch("auth/logout", {}, { root: true }).then(() => {});
          }
          if (err.response.status == 422) {
            console.log(err);
            resolve(err.response);
          }
        });
    });
  },
};
