<template>
  <div class="modal" id="convertPopUp">
    <div class="modal-content modal-panel relative">
      <div class="modal-header">
        <button
          class="modal-close-btn w-ba"
          @click="closePopup('convertPopUp')"
        ></button>
        <div class="modal-banner flex flex-col justify-center items-center">
          <a href="javascript:void(0)" class="modal-logo flex justify-center">
            <img src="@/assets/img/dream.png" alt="Dream Logo" />
          </a>
        </div>
      </div>
      <div class="modal-body mt-5">
        <div class="content w-ba pt-5" style="min-height: 0">
          <div
            id="my-info-section"
            style="overflow: hidden; position: relative"
          >
            <div v-if="loading">{{ $t("loading") }}</div>
            <div class="board-panel" style="min-height: 0" v-if="!loading">
              <table width="100%">
                <colgroup>
                  <col width="50%" />
                  <col width="50%" />
                </colgroup>

                <tbody>
                  <tr>
                    <td class="text-center" style="color: yellow">
                      {{ $t("balance") }}
                    </td>
                    <td class="text-center">
                      {{ numberString(Math.floor(profile.balance)) }}
                    </td>
                  </tr>
                  <tr>
                    <td class="text-center border-bottom" style="color: yellow">
                      {{ $t("points") }}
                    </td>

                    <td class="text-center border-bottom">
                      {{ numberString(Math.floor(profile.points)) }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="form-footer dflex-ac-jc">
          <button
            type="button"
            class="btn-yellow login-link login-btn"
            @click="convertPoints"
          >
            {{ $t("convert") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ui from "@/mixins/ui";
import datetime from "@/mixins/datetime";
import number from "@/mixins/number";
import { mapActions, mapGetters } from "vuex";
import Swal from "sweetalert2";

export default {
  name: "Convert Points",
  mixins: [ui, datetime, number],
  data() {
    return {

    };
  },
  computed: {
    ...mapGetters("auth", ["authenticated", "profile"]),
  },

  methods: {
    ...mapActions("auth", {
      authGetProfile: "getProfile",
    }),
    ...mapActions("points", ["convert"]),
    async convertPoints() {
      const confirmed = await Swal.fire({
        title: this.$t("Are you sure?"),
        type: "warning",
        icon: "question",
        showCancelButton: true,
        padding: "2em",
        confirmButtonText: this.$t("ok"),
        cancelButtonText: this.$t("cancel"),
      }).then((result) => {
        return result.isConfirmed;
      });
      if (confirmed) {
        const res = await this.convert();
        if (res.status == 200) {
          Swal.fire({
            background: "#2a3042",
            color: "#fff",
            icon: "success",
            title: res.data.message,
          });
          await this.authGetProfile();
        }
      }
    },
  },
  mounted() {
  },
};
</script>
