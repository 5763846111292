import { mapActions } from 'vuex'
export default {
	computed: {
		gameLaunchMode() {
			return process.env.VUE_APP_GAME_LAUNCH_MODE;
		},
	},
    methods: {
		...mapActions('auth', {
			authGetProfile: 'getProfile',
		}),
		...mapActions('game', ['launchMamahot','exitMamahot']),
		openPopup(id) {
			let popupBg = document.getElementById(id)
			popupBg.classList.add("bg-active");
			popupBg.addEventListener('mousedown', function (e) {
				e = window.event || e;
				if (this === e.target) {
					popupBg.classList.remove('bg-active')
				}
			});
		},
		closePopup(id) {
			let popupBg = document.getElementById(id)
			popupBg.classList.remove("bg-active");
		},
		async openGameWindow($url, window_id, vendor) {
			var queryParams = {
				url: $url,
				vendor: vendor,
			};
				
			var queryString = Object.keys(queryParams)
				.map(
					(key) =>
						key + '=' + encodeURIComponent(queryParams[key])
				)
				.join('&');
			var window_name =
				this.gameLaunchMode === 'single'
					? 'game'
					: `game-${window_id}`;

			// var windowOpen = window.open($url,`_blank`);

			var windowOpen = window.open(
				`/game-window/casino?${queryString}`,
				window_name,
				'width=' +
				screen.availWidth +
				',height=' +
				screen.availHeight +
				',fullscreen=yes, toolbar=no, location=no, directories=no, status=no, menubar=no,scrollbars=no,resizable=no'
			);
			
			var polling = null;
			polling = setInterval(async ()=> {
				try {
					if(windowOpen.closed) {
						clearInterval(polling)
						await this.exitMamahot();
						await this.authGetProfile();
					}
				} 
				catch (e) { }
			} , 1000)

			var vm = this
			window.addEventListener('beforeunload', async function () {
    			try {
					vm.$store.commit('closeAllWindow');
					await this.exitMamahot();
					windowOpen.close();

				} catch (e) {
					console.error(e)
				}
			});
		}
    }
}