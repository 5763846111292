<template>
  <div class="modal" id="ticketPopUp">
    <div class="modal-content modal-panel event-notice relative">
      <div class="modal-header">
        <button
          class="modal-close-btn w-ba"
          @click="closePopup('ticketPopUp')"
        ></button>
        <div class="modal-banner flex flex-col justify-center items-center">
          <a href="javascript:void(0)" class="modal-logo flex justify-center">
            <img src="@/assets/img/dream.png" alt="Dream Logo" />
          </a>
        </div>
      </div>
      <div class="modal-body mt-5">
        <div class="content w-ba pt-5" style="min-height: 0 !important;">
          <div id="event-section" style="overflow: hidden; position: relative;">
            <div class="board-panel">
              <table width="100%" v-if="data">
                <colgroup>
                  <col width="50%" />
                  <col width="50%" />
                </colgroup>
                <thead>
                  <tr>
                    <th class="title text-center">
                      <div style="vertical-align: inherit;">
                        {{ $t('subject') }}
                      </div>
                    </th>
                    <th class="title text-center">
                      <div style="vertical-align: inherit;">
                        {{ $t('date') }}
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="r in data.list" :key="r._id">  
     
                    <td
                      class="text-left cursor-pointer flex items-center"
                      @click="viewTicket(r._id)"
                    >
                      <img
                        style="width: 20px;"
                        class="mr-2"
                        :src="
                          r.customer_read == true
                            ? require('@/assets/img/read.png')
                            : require('@/assets/img/unread.png')
                        "
                      />
              
                      {{ r.subject }}
                    </td>
                    <td class="text-center">
                      {{ r.created_at }}
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="mt-5">
                <Pagination
                  :data="data"
                  @emitPage="ticketList"
                  v-if="data.list.length"
                  class="w-full"
                />
              </div>

              <div
                class="form-group"
                style="margin-top: 20px;"
                v-if="data.list.length <= 0 && !loading"
              >
                <div class="form-footer dflex-ac-jc pagination text-base">
                  <div>{{ $t('there is no history') }}</div>
                </div>
              </div>
              <div class="form-group" style="margin-top: 20px;" v-if="loading">
                <div class="form-footer dflex-ac-jc pagination text-base">
                  <div>{{ $t('loading') }}...</div>
                </div>
              </div>
              <div class="form-footer dflex-ac-jc">
                <!-- <button class="btn-yellow">Quick account inquiry</button> -->
                <button class="btn-red" @click="openPopup('composeTicket')">
                  {{ $t('contact us') }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ViewTicketPopUp
    :data="ticket"
    @submitComment="submitComment"
    :reply="reply"
    @reset="resetReply"
  />
  <ComposeTicketPopUp @emitPage="ticketList" />
</template>

<script>
import Layout from '@/components/Layout'
// import Loader from "@/components/Loader.vue";
import Pagination from '@/components/Pagination'
import ui from '@/mixins/ui'
import datetime from '@/mixins/datetime'
import number from '@/mixins/number'
import Swal from 'sweetalert2'
import { mapActions, mapGetters } from 'vuex'
import ViewTicketPopUp from '@/components/ViewTicket.vue'
import ComposeTicketPopUp from '@/components/ComposeTicketPopUp.vue'

export default {
  name: 'Announcement',
  components: {
    Layout,
    Pagination,
    ViewTicketPopUp,
    // Loader,
    ComposeTicketPopUp,
  },
  mixins: [ui, datetime, number],
  data() {
    return {
      data: {
        list: [],
        links: [],
        current_page: 1,
        per_page: 1,
        last_page: 1,
        total: 0,
      },
      ticket: {
        _id: null,
        subject: '',
        content: '',
        status: '',
        comments: [],
        customer: {},
        created_at: '',
      },
      reply: {
        content: '',
      },
    }
  },
  computed: {
    ...mapGetters('auth', ['authenticated', 'profile']),
  },
  watch: {
    'authenticated' (loggedIn) {
      if(loggedIn) {
          this.ticketList()
      }
    }
  },
  methods: {
    ...mapActions('auth', {
      authGetProfile: 'getProfile',
    }),
    ...mapActions('ticket', {
      ticketGetList: 'getList',
      ticketView: 'view',
      create: 'createTicket',
      commentCreate: 'commentCreate',
    }),

    emitPage(page) {
      this.$emit('emitPage', parseInt(page))
    },
    setActiveTab(tab) {
      this.active = tab
    },
    goToCreateTicket() {
      this.openPopup('composeTicket')
    },
    async ticketList(p) {
      var pl = {
        page_no: p ? p : 1,
        limit: 10,
      }
      const data = await this.ticketGetList(pl)
      this.data.list = data.data
      this.data.links = data.links
      this.data.current_page = data.current_page
      this.data.per_page = data.per_page
      this.data.last_page = data.last_page
    },
    async submitComment(_id) {
      const res = await this.commentCreate({
        ticket_id: _id,
        form_data: this.reply,
      })
      if (res) {
        if (res.success) {
          this.viewTicket(_id)
          document.querySelector('.ql-editor').innerHTML = null
        }
        Swal.fire({
          title: res.success
            ? this.$t('comment has been created')
            : this.$t('creating comment failed'),
          icon: res.success ? 'success' : 'error',
          timer: 2000,
        })
      }
    },
    async viewTicket(id) {
      const data = await this.ticketView(id)
      this.openPopup('viewTicket')
      this.authGetProfile()
      this.ticket._id = data._id
      this.ticket.subject = data.subject
      this.ticket.content = data.content
      this.ticket.comments = data.comments
      this.ticket.status = data.status
      this.ticket.customer.username = data.customer.username
      this.ticket.created_at = data.created_at
    },
    resetReply() {
      document.querySelector('.ql-editor').innerHTML = null
    },
  },
  mounted() {
  if(this.authenticated) {
      this.ticketList();
    }
  },
}
</script>
