<template>
  <div class="modal" id="viewAnnouncement">
    <div class="modal-content modal-panel event-notice relative">
      <div class="modal-header">
        <button
          class="modal-close-btn w-ba"
          @click="closePopup('viewAnnouncement')"
        ></button>
        <div class="modal-banner flex flex-col justify-center items-center">
          <a href="javascript:void(0)" class="modal-logo flex justify-center">
            <img src="@/assets/img/dream.png" alt="Dream Logo" />
          </a>
        </div>
      </div>
      <div class="modal-body mt-5">
        <div class="content w-ba pt-5">
          <div id="event-section" style="overflow: hidden; position: relative">
            <table width="100%">
              <tbody>
                <h2>
                  {{ data.subject }}
                </h2>
                <div style="font-size: 0.8rem">
                  {{ data.user.name }} | {{ data.user.username }}
                </div>
                <div style="font-size: 0.8rem">
                  {{ data.created_at }}
                </div>
                <div class="mt-5">
                  <div v-html="data.content"></div>
                </div>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ui from "@/mixins/ui";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Event View",
  components: {},
  mixins: [ui],
  data() {
    return {};
  },
  props: {
    data: {
      type: Object,
    },
  },
  computed: {
    ...mapGetters("auth", ["authenticated", "profile"]),
  },
  methods: {
    ...mapActions("auth", {
      authGetProfile: "getProfile",
    }),
  },
  mounted() {},
};
</script>
