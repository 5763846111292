import axios from "axios";

export const state  = {
    data: {
        list: [],
        links: [],
        current_page: 0,
        last_page: 0,
        per_page: 0,
        total: 0,
      },
}

 
export const actions = {
    getList({ commit }) {
        return new Promise(function () {
          axios
            .get("/data/mini_section.json")
            .then(function (res) {
              commit("setData", res);
            })
            .catch(function (err) {
              console.log(err);
            });
        });
      },
}
export const mutations = {
    setData(state, data) {
        state.data.list = data.data
    }
}

