<template>
  <div class="modal" id="composeTicket">
    <div class="modal-content modal-panel event-notice relative">
      <div class="modal-header">
        <button class="modal-close-btn w-ba" @click="close"></button>
        <div class="modal-banner flex flex-col justify-center items-center">
          <a href="javascript:void(0)" class="modal-logo flex justify-center">
            <img src="@/assets/img/dream.png" alt="Dream Logo" />
          </a>
        </div>
      </div>
      <div class="modal-body mt-5">
        <div class="content w-ba pt-5" style="min-height: 0;">
          <div class="input-group" style="margin: 10px 0;">
            <input
              type="text"
              :placeholder="$t('subject')"
              id="customerTitle"
              class="form-control"
              v-model="subject"
              style="
                border: 1px solid #333;
                background-color: #222222;
                color: #fff;
                width: 100%;
              "
            />
          </div>
          <div
            v-for="(item, index) in v$.subject.$errors"
            :key="index"
            class="invalid-feedback"
          >
            <span v-if="item.$message">
              {{ $t(item.$message) }}
            </span>
          </div>
          <div class="input-group">
            <div style="background: #222222; height: 100%; width: 100%;">
              <QuillEditor
                theme="snow"
                :options="editorOptions"
                contentType="html"
                ref="myEditor"
                class="custom-quill-editor"
                v-model:content="content"
              />
            </div>
          </div>
          <div
            v-for="(item, index) in v$.content.$errors"
            :key="index"
            class="invalid-feedback"
          >
            <span v-if="item.$message">
              {{ $t(item.$message) }}
            </span>
          </div>
        </div>
        <div class="form-footer dflex-ac-jc">
          <button class="btn-red" @click="closePopup('composeTicket')">
            {{ $t('return') }}
          </button>
          <button class="btn-yellow" @click="submitTicket">
            {{ $t('submit') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ui from '@/mixins/ui'
import { required, minValue, helpers } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'
import { mapActions } from 'vuex'
import Swal from 'sweetalert2'
export default {
  setup() {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      submitted: false,
      subject: null,
      content: null,

      editorContent: '',
      editorOptions: {
        // Vue Quill Editor configuration options
        placeholder: '내용 입력...',
        modules: {
          toolbar: [
            [{ color: [] }, { background: [] }],
            ['bold', 'italic', 'underline', 'strike'],
            ['blockquote', 'code-block'],
            [{ header: 1 }, { header: 2 }],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ script: 'sub' }, { script: 'super' }],
            [{ indent: '-1' }, { indent: '+1' }],
            [{ size: ['small', false, 'large', 'huge'] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],

            [{ font: [] }],
            [{ align: [] }],
            ['clean'],
            ['image'],
          ],
        },
      },
    }
  },
  validations() {
    return {
      subject: {
        required: helpers.withMessage('required', required),
      },
      content: {
        required: helpers.withMessage('required', required),
      },
    }
  },
  mixins: [ui],
  methods: {
    ...mapActions('ticket', {
      ticketCreate: 'createTicket',
    }),
    close() {
      this.closePopup('composeTicket')
      this.reset()
    },
    async submitTicket() {
      this.submitted = true
      this.v$.$touch()
      if (this.v$.$invalid) {
        return
      } else {
        const confirmed = await Swal.fire({
          title: 'Are you sure?',
          text: 'This will create new ticket',
          type: 'warning',
          icon: 'question',
          showCancelButton: true,
          padding: '2em',
        }).then((result) => {
          return result.isConfirmed
        })
        if (confirmed) {
          var fd = new FormData()
          fd.append('subject', this.subject)
          fd.append('content', this.content)
          const res = await this.ticketCreate(fd)
          console.log(res)

          if (res.status == 200) {
            this.closePopup('composeTicket')
            this.reset()
            this.$emit('emitPage', 1)
            Swal.fire({
              icon: 'success',
              title: res.message,
              timer: 2000,
            })
          } else {
            Swal.fire({
              icon: 'error',
              title: this.$t('creating ticket failed'),
              html: this.$t(res.message),
              confirmButtonText: this.$t('OK'),
            })
          }
        }
      }
    },
    reset() {
      this.subject = null
      this.content = null
      document.querySelector('.ql-editor').innerHTML = null
    },
  },
}
</script>
