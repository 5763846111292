import axios from "axios";
import $api from "../api";

export const state = {
  data: {
    list: [],
    links: [],
    current_page: 0,
    last_page: 0,
    per_page: 0,
    total: 0,
  },
  list: {
    list: [],
    links: [],
    current_page: 0,
    last_page: 0,
    per_page: 0,
    total: 0,
  },
};

export const actions = {
  getList({ commit, rootGetters, dispatch }, pl) {
    return new Promise(function (resolve) {
      axios
        .get("/data/slot_lobby.json", {
          headers: {
            Authorization: rootGetters["auth/bearer_token"],
          },
        })
        .then(function (res) {
          if (res.status == 200) {
            commit("setData", res);
            resolve(true);
          }
        })
        .catch(function (err) {
          console.log(err, "err");
          if (err.response.status == 401) {
            dispatch("auth/logout", {}, { root: true }).then(() => {});
          }
        });
    });
  },
  getGameList({ commit, rootGetters, dispatch }, pl) {
    return new Promise(function (resolve) {
      axios
        .get(`/data/updated-slot-gamelist/${pl.game_id}.json`, {
          headers: {
            Authorization: rootGetters["auth/bearer_token"],
          },
        })
        .then(function (res) {
          if (res.status == 200) {
            commit("setGameData", res);
            resolve(true);
          }
        })
        .catch(function (err) {
          console.log(err, "err");
          if (err.response.status == 401) {
            dispatch("auth/logout", {}, { root: true }).then(() => {});
          }
        });
    });
  },

  getGameLaunch({ commit, rootGetters, dispatch }, pl) {
    return new Promise(function (resolve) {
      $api
        .get(`player/game-launch?game_id=${pl.game_id}&vendor=${pl.vendor}`, {
          headers: {
            Authorization: rootGetters["auth/bearer_token"],
          },
        })
        .then(function (res) {
          if (res.status == 200) {
            // console.log("gamelaunch", res);
            // commit("setGameLaunch", res.data.data);
            resolve(res);
          }
        })
        .catch(function (err) {
          if (err.response.status == 401) {
            resolve(err.response);
            // dispatch("auth/logout", {}, { root: true }).then(() => {});
          
          }
          if (err.response.status == 422) {
            console.log(err);
            resolve(err.response);
          }
        });
    });
  },
};
export const mutations = {
  setData(state, data) {
    state.data.list = data.data;
    state.data.links = data.links;
    state.data.current_page = data.current_page;
    state.data.per_page = data.per_page;
    state.data.last_page = data.last_page;
  },
  setGameData(state, data) {
    state.list.list = data.data;
  },
};
