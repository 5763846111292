<template>
  <div class="modal" id="couponPopUp">
    <div class="modal-content modal-panel coupon relative">
      <div class="modal-header">
        <button
          class="modal-close-btn w-ba"
          @click="closePopup('couponPopUp')"
        ></button>
        <div class="modal-banner flex flex-col justify-center items-center">
          <a href="javascript:void(0)" class="modal-logo flex justify-center">
            <img src="@/assets/img/dream.png" alt="Dream Logo" />
          </a>
        </div>
      </div>
      <div class="modal-body mt-5">
       <h2>{{ $t("Coupon") }}</h2>
        <div class="content w-ba pt-5">
             
          <div class="board-panel">
            <table width="100%">
              <colgroup>
                <col width="10%" />
                <col width="25%" />
                <col width="25%" />
                <col width="20%" />
                <col width="20%" />
              </colgroup>
              <thead>
                <tr>
                  <th class="title text-left">#</th>
                  <th class="title text-left">{{ $t("code") }}</th>
                  <th class="title text-left">{{ $t("amount") }}</th>
                  <th class="title text-left">{{ $t("status") }}</th>
                  <th class="title text-left">{{ $t("date") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(data, index) in data.list" :key="index">
                  <td>
                    {{
                      index +
                      1 +
                      (this.data.current_page - 1) * this.data.per_page
                    }}
                  </td>
                  <td
                    :class="
                      data.status == 'unclaimed' ? 'cursor-pointer' : null
                    "
                    @click="data.status == 'unclaimed' ? claim(data._id) : null"
                  >
                    {{ data.code }}
                  </td>
                  <td @click="show">
                    {{ numberString(data.amount) }}
                  </td>
                  <td>{{ data.status }}</td>
                  <td>{{ data.created_at }}</td>
                </tr>
                <!-- <tr
                        style="display: table-row; position: relative"
                        v-if="viewCoupon"
                         colspan=""
                      >
                        <td colspan="6" style="text-align: center; width:100%" class="image"> 
                          <img src="@/assets/img/lottery_1.png" alt="" />
                        </td>
                        <td colspan="6"  class="coupon-amount">100,000</td>
                      </tr> -->
              </tbody>
            </table>
            <div class="mt-5">
              <Pagination
                :data="data"
                @emitPage="emitPage"
                v-if="data.list.length > 0"
              />
            </div>
            <div
              class="form-group"
              style="margin-top: 20px"
              v-if="data.list.length <= 0 && !loading"
            >
              <div class="form-footer dflex-ac-jc pagination text-base">
                <div>{{ $t("there is no history") }}</div>
              </div>
            </div>
            <div class="form-group" style="margin-top: 20px" v-if="loading">
              <div class="form-footer dflex-ac-jc pagination text-base">
                <div>{{ $t("loading...") }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Layout from "@/components/Layout";
// import Loader from "@/components/Loader.vue";
import Pagination from "@/components/Pagination";
import ui from "@/mixins/ui";
import datetime from "@/mixins/datetime";
import number from "@/mixins/number";
import { mapActions, mapGetters } from "vuex";
import Swal from "sweetalert2";

export default {
  name: "Coupon",
  components: {
    Layout,
    Pagination,
  },
  mixins: [ui, datetime, number],
  data() {
    return {
      data: {
        list: [],
        links: [],
        current_page: 1,
        per_page: 1,
        last_page: 1,
        total: 0,
      },
    };
  },
  computed: {
    ...mapGetters("auth", ["authenticated", "profile"]),
  },
  watch: {
    'authenticated' (loggedIn) {
      if(loggedIn) {
      console.log(loggedIn)
        this.couponList(1) 
      }
    }
  },
  methods: {
    ...mapActions("auth", {
      authGetProfile: "getProfile",
    }),
   ...mapActions("coupon", {
      couponGetList: "getList",
      claimCoupon: "getCoupon",
    }),
    async couponList(p) {
      var pl = {
        page_no: p ? p : 1,
        limit: 50,
      };
      this.loading = true;
      const data = await this.couponGetList(pl);
      this.data.list = data.data;
      this.data.links = data.links;
      this.data.current_page = data.current_page;
      this.data.per_page = data.per_page;
      this.data.last_page = data.last_page;

      this.loading = false;
      //   this.openPopup("couponPopUpEventModal");
    },
     async claim(_id) {
      const confirmed = await Swal.fire({
        title: "Are you sure?",
        text: "This will claim the coupon.",
        type: "warning",
        icon: "question",
        showCancelButton: true,
        padding: "2em",
      }).then((result) => {
        return result.isConfirmed;
      });
      if (confirmed) {
        const res = await this.claimCoupon(_id);
        if (res.status == 200) {
          Swal.fire({
            background: "#2a3042",
            color: "#fff",
            icon: "success",
            title: this.$t(res.data.message),
          });
          this.couponList(1);
        } else {
          Swal.fire({
            background: "#2a3042",
            color: "#fff",
            icon: "failed",
            title: res.data.message,
          });
        }
      }
    },
  },
  mounted() {
    // this.couponList();
     if(this.authenticated) {
        // this.couponList(1)
    }
  },
};
</script>
