import $api from "../api";
import Swal from "sweetalert2";


export const state = {
    data: {
      list: [],
      links: [],
      current_page: 0,
      last_page: 0,
      per_page: 0,
      total: 0,
    },
  }

  export const  actions = {
    getList({ rootGetters, dispatch }, pl) {
        return new Promise(function (resolve) {
          $api
            .get(
              `player/points?${pl.page_no ? 'page=' + pl.page_no : ''}${
                pl.limit ? '&limit=' + pl.limit : '&limit=50'
              }`,
              {
                headers: {
                  Authorization: rootGetters['auth/bearer_token'],
                },
              },
            )
            .then(function (res) {
              if (res.status == 200) {
                resolve(res.data.data)
              }
            })
            .catch(function (err) {
              if (err.response.status == 401) {
                dispatch('auth/logoutUser', {}, { root: true }).then(() => {})
              }
            })
        })
      },
    convert({ rootGetters, dispatch }, pl) {
      return new Promise(function (resolve) {
           var path = rootGetters["auth/role"] =='player'? 'player/points/convert' :'agent/commissions/convert'
        $api
          .post(path, pl, {
            headers: {
              Authorization: rootGetters["auth/bearer_token"],
            },
          })
          .then(function (res) {
            if (res.status == 200) {
              // Swal.fire({
              //   background: "#2a3042",
              //   color: "#fff",
              //   icon: "success",
              //   title: res.data.message,
              // });
              resolve(res);
            }
          })
          .catch(function (err) {
            if (err.response.status == 401) {
              dispatch("auth/logout", {}, { root: true }).then(() => {});
            }
            if (err.response.status == 400) {
              Swal.fire({
                icon: "error",
                title: "Failed",
                text: err.response.data.message,
                confirmButtonColor: "#f46a6a",
              });
              console.log(err);
              resolve(err.response);
            }
            if (err.response.status == 422) {
              resolve(err.response);
            }
          });
      });
    },
  }
  export const mutations= {
    setData(state, data) {
      state.data.list = data.data;
      state.data.links = data.links;
      state.data.current_page = data.current_page;
      state.data.per_page = data.per_page;
      state.data.last_page = data.last_page;
  },
};
