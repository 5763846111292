<template>
  <div class="modal" id="openNotice">
    <div class="modal-content modal-panel event-notice relative">
      <div class="modal-header">
        <button
          class="modal-close-btn w-ba"
          @click="closePopup('openNotice')"
        ></button>
        <div class="modal-banner flex flex-col justify-center items-center">
          <a href="javascript:void(0)" class="modal-logo flex justify-center">
            <img src="@/assets/img/dream.png" alt="Dream Logo" />
          </a>
        </div>

        <div class="realtime-nav dflex-ac-jc p-2">
          <button
            id="event-btn"
            @click="setActiveTab('event')"
            :class="active == 'event' ? 'active-btn' : ''"
          >
            <font-awesome-icon
              class="text-lg lg:text-md header-icon mr-1 mr-2"
              icon="fa-solid fa-trophy"
            />
            {{ $t("event") }}
          </button>
          <button
            id="notice-btn"
            @click="setActiveTab('announcement')"
            :class="active == 'announcement' ? 'active-btn' : ''"
          >
            <font-awesome-icon
              class="text-lg lg:text-md header-icon mr-1 mr-2"
              icon="fa-solid fa-crown"
            />
            {{ $t("notice") }}
          </button>
        </div>
      </div>
      <div class="modal-body mt-5">
        <div class="content w-ba pt-5">
          <div
            v-if="active == 'event'"
            id="event-section"
            style="overflow: hidden; position: relative"
          >
            <div class="board-panel event">
              <table width="100%" v-if="eventData">
                <colgroup>
                  <col width="50%" />
                  <col width="50%" />
                </colgroup>
                <thead>
                  <tr>
                    <th class="title text-center">
                      <div style="vertical-align: inherit">
                        {{ $t("subject") }}
                      </div>
                    </th>
                    <th class="title text-center">
                      <div style="vertical-align: inherit">
                        {{ $t("date") }}
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="r in eventData.list" :key="r._id">
                    <td
                      class="text-left cursor-pointer"
                      @click="viewEvent(r._id)"
                    >
                      {{ r.subject }}
                    </td>
                    <td class="text-center">
                      {{ r.created_at }}
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="mt-2">
                <Pagination
                  :data="eventData"
                  @emitPage="eventList"
                  class="w-full"
                  v-if="eventData.list.length"
                />
              </div>

              <div
                class="form-group"
                style="margin-top: 20px"
                v-if="eventData.list.length <= 0 && !loading"
              >
                <div class="form-footer dflex-ac-jc pagination text-base">
                  <div>No history</div>
                </div>
              </div>
              <div class="form-group" style="margin-top: 20px" v-if="loading">
                <div class="form-footer dflex-ac-jc pagination text-base">
                  <div>Loading.....</div>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="active == 'announcement'"
            id="event-section"
            style="overflow: hidden; position: relative"
          >
            <div class="board-panel event">
              <table width="100%" v-if="data">
                <colgroup>
                  <col width="50%" />
                  <col width="50%" />
                </colgroup>
                <thead>
                  <tr>
                    <th class="title text-center">
                      <div style="vertical-align: inherit">
                        {{ $t("subject") }}
                      </div>
                    </th>
                    <th class="title text-center">
                      <div style="vertical-align: inherit">
                        {{ $t("date") }}
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="r in data.list" :key="r._id">
                    <td
                      class="text-left cursor-pointer"
                      @click="viewAnnouncement(r._id)"
                    >
                      {{ r.subject }}
                    </td>
                    <td class="text-center">
                      {{ r.created_at }}
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="mt-2">
                <Pagination
                  :data="data"
                  @emitPage="announcementList"
                  class="w-full"
                  v-if="data.list.length"
                />
              </div>
              <div
                class="form-group"
                style="margin-top: 20px"
                v-if="data.list.length <= 0 && !loading"
              >
                <div class="form-footer dflex-ac-jc pagination text-base">
                  <div>No history</div>
                </div>
              </div>
              <div class="form-group" style="margin-top: 20px" v-if="loading">
                <div class="form-footer dflex-ac-jc pagination text-base">
                  <div>Loading.....</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ViewEventPopUp :data="event" />
  <ViewAnnouncementPopUp :data="announcement" />
</template>

<script>
import Layout from "@/components/Layout";
// import Loader from "@/components/Loader.vue";
import Pagination from "@/components/Pagination";
import ui from "@/mixins/ui";
import datetime from "@/mixins/datetime";
import number from "@/mixins/number";
import { mapActions, mapGetters } from "vuex";
import ViewEventPopUp from "@/components/ViewEvent.vue";
import ViewAnnouncementPopUp from "@/components/ViewAnnouncement.vue";
export default {
  name: "Notice / Event",
  components: {
    Layout,
    Pagination,
    // Loader,
    ViewEventPopUp,
    ViewAnnouncementPopUp,
  },
  mixins: [ui, datetime, number],
  data() {
    return {
      //   loading: false,
      active: "event",
      event: {
        content: "",
        subject: "",
        user: {},
        created_at: "",
      },
      announcement: {
        content: "",
        subject: "",
        user: {},
        created_at: "",
      },
      eventData: {
        list: [],
        links: [],
        current_page: 1,
        per_page: 1,
        last_page: 1,
        total: 0,
      },
      data: {
        list: [],
        links: [],
        current_page: 1,
        per_page: 1,
        last_page: 1,
        total: 0,
      },
    };
  },
  computed: {
    ...mapGetters("auth", ["authenticated", "profile" , 'role']),
  },
  watch: {
    'authenticated' (loggedIn) {
      if(loggedIn) {
        this.announcementList();
        this.eventList();
      }
    }
  },
  methods: {
    ...mapActions("auth", {
      authGetProfile: "getProfile",
    }),
    ...mapActions("announcement", {
      announcementGetList: "getList",
      announcementView: "view",
    }),
    ...mapActions("event", {
      eventGetList: "getList",
      eventView: "view",
    }),
    async announcementList(p) {
      var pl = {
        page_no: p ? p : 1,
        limit: 50,
      };
      this.loading = true;
      const data = await this.announcementGetList(pl);
      this.data.list = data.data;
      this.data.links = data.links;
      this.data.current_page = data.current_page;
      this.data.per_page = data.per_page;
      this.data.last_page = data.last_page;

      this.loading = false;
      //   this.openPopup("openNoticeEventModal");
    },
    async eventList(p) {
      var pl = {
        page_no: p ? p : 1,
        limit: 50,
      };
      this.loading = true;
      const data = await this.eventGetList(pl);
      this.eventData.list = data.data;
      this.eventData.links = data.links;
      this.eventData.current_page = data.current_page;
      this.eventData.per_page = data.per_page;
      this.eventData.last_page = data.last_page;

      this.loading = false;
      //   this.openPopup("openNoticeEventModal");
    },
    setActiveTab(tab) {
      console.log(tab);
      this.active = tab;
    },
    async viewAnnouncement(id) {
      const data = await this.announcementView(id);
      this.openPopup("viewAnnouncement");
      console.log(data);
      //   this._id = data._id
      this.announcement.subject = data.subject;
      this.announcement.content = data.content;
      this.announcement.user.name = data.user.name;
      this.announcement.user.username = data.user.username;
      this.announcement.created_at = data.created_at;
    },
    async viewEvent(id) {
      const data = await this.eventView(id);
      this.openPopup("viewEvent");
      console.log(data);
      //   this._id = data._id
      this.event.subject = data.data.subject;
      this.event.content = data.data.content;
      this.event.user.name = data.data.user.name;
      this.event.user.username = data.data.user.username;
      this.event.created_at = data.data.created_at;
    },
  },
  mounted() {
    if(this.authenticated) {
        this.announcementList();
      if(this.role=='player') {
         this.eventList();
      }
    }
  },
};
</script>
