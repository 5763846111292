<template>
  <div class="modal" id="withdrawPopUp">
    <div class="modal-content modal-panel relative">
      <div class="modal-header">
        <button class="modal-close-btn w-ba" @click="close"></button>
        <div class="modal-banner flex flex-col justify-center items-center">
          <a href="javascript:void(0)" class="modal-logo flex justify-center">
            <img src="@/assets/img/dream.png" alt="Dream Logo" />
          </a>
        </div>
      </div>
      <div class="modal-body mt-5">
        <h2>{{$t('withdraw')}}</h2>
        <div class="content w-ba pt-5">
          <form action="" class="modal-form" @submit.prevent="submit">
            <div class="form-group">
              <label for="amount" class="py-2 block">{{ $t("amount") }}</label>
              <VueNumberFormat
                id="amount"
                class="number"
                :class="{
                  'is-invalid': submitted && v$.amount.$error,
                }"
                v-model:value="amount"
                :options="{
                  precision: 0,
                  prefix: '',
                  suffix: '',
                  decimal: '.',
                  thousand: ',',
                  acceptNegative: false,
                  isInteger: true,
                }"
              ></VueNumberFormat>
              <div
                v-for="(item, index) in v$.amount.$errors"
                :key="index"
                class="invalid-feedback"
              >
                <span v-if="item.$message">
                  {{ $t(item.$message) }}
                </span>
              </div>
            </div>

            <div class="preset-amount flex flex-wrap gap-3">
              <button
                v-for="n in preset_amounts"
                :key="n"
                type="button"
                @click="selectAmount(n)"
                class="btn-yellow custom-btn"
                :class="{ 'clear-btn': n == 0 }"
              >
                {{ n == 0 ? $t("clear") : numberString(n) }}
              </button>
            </div>
            <div
              class="form-group mt-2"
              v-if="profile.withdraw.password === true"
            >
              <label for="amount" class="py-2 block">{{
                $t("password")
              }}</label>
              <input type="password" v-model="password" />
              <!-- <div
                v-for="(item, index) in v$.password.$errors"
                :key="index"
                class="invalid-feedback"
              >
                <span v-if="item.$message">
                  {{ $t(item.$message) }}
                </span>
              </div> -->
            </div>
            <div class="form-group mt-2" v-if="profile.withdraw.pin === true">
              <label for="amount" class="py-2 block">{{ $t("pin") }}</label>
              <input
                name="pincode"
                type="password"
                inputmode="numeric"
                maxlength="4"
                v-model="pin"
              />
              <!-- <div
                v-for="(item, index) in v$.password.$errors"
                :key="index"
                class="invalid-feedback"
              >
                <span v-if="item.$message">
                  {{ $t(item.$message) }}
                </span>
              </div> -->
            </div>
          </form>
        </div>
        <div class="form-footer dflex-ac-jc">
          <button type="button" class="btn-yellow" @click="submit">
            {{$t('submit')}}
          </button>
          <button
            type="button"
            class="btn-red"
            @click="closePopup('withdrawPopUp')"
          >
            {{$t('cancel')}}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ui from "@/mixins/ui";
import number from "@/mixins/number";
import { required, minValue, helpers } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import { mapActions, mapGetters } from "vuex";
import Swal from "sweetalert2";
export default {
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      submitted: false,
      preset_amounts: [10000, 50000, 100000, 250000, 500000, 0],
      bank_name: null,
      account_holder: null,
      account_number: null,
      amount: 0,
      pin: null,
      password: null,
    };
  },
  validations() {
    return {
      amount: {
        required: helpers.withMessage("required", required),
        minValueValue: minValue(1000),
      },
    };
  },
  computed: {
    ...mapGetters("auth", ["profile"]),
  },
  mixins: [ui, number],
  methods: {
    ...mapActions("auth", {
      authGetProfile: "getProfile",
    }),
    ...mapActions("transaction", {
      transactionWithdraw: "withdraw",
    }),
    selectAmount(n) {
      this.amount = n;
    },
    async submit() {
      this.v$.$touch();
      this.submitted = true;
      if (this.v$.$invalid) {
        return;
      } else {
        const confirmed = await Swal.fire({
          title: "Are you sure?",
          text: "This will create withdraw",
          type: "warning",
          showCancelButton: true,
          padding: "2em",
          icon: "question",
        }).then((result) => {
          return result.isConfirmed;
        });
        if (confirmed) {
          // this.v$.$validate();

          const res = await this.transactionWithdraw({
            amount: this.amount,
            pin: this.pin,
            password: this.password,
          });
          if (res.status == 200) {
            Swal.fire({
              title: "Success",
              html: res.data.message,
              type: "success",
              icon: "success",
              padding: "2em",
            });
            this.amount = "";
            this.closePopup("withdrawPopUp");
          } else if (res.status == 420) {
            Swal.fire("Failed!", this.$t(res.data.message), "error");
            // this.openPopup('')
          } else if (res.status == 422 || res.status == 400) {
            Swal.fire("Failed!", this.$t(res.data.message), "error");
            var error = res.data.errors;
            console.log(error);
          }
        }
      }
    },
    async getBank() {
      const res = await this.transactionGetBank();
      if (res) {
        this.bank_name = res.bank_name;
        this.account_holder = res.account_holder;
        this.account_number = res.account_number;
      }
    },
    close() {
      this.closePopup("withdrawPopUp");
      this.reset();
    },
    reset() {
      this.bank_name = null;
      this.account_holder = null;
      this.account_number = null;
      this.amount = 0;
      this.submitted = false;
    },
  },
  mounted() {
  }
};
</script>
