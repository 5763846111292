<template>
  <Layout>
    <Banner />
    <PageHeader />
    <GamePage />
    <BoardSection v-if="authenticated"/>
    <HistoryList  v-if="authenticated"/>
    <Popup />
    <LoginPopup />
    <RegisterPopup />
    <ProfilePopUp />
  </Layout>
</template>

<script>
import ui from "@/mixins/ui";
import { mapActions, mapGetters } from "vuex";
import Swal from "sweetalert2";
import Layout from "@/components/Layout";
import Banner from "@/components/Banner.vue";
import GamePage from "@/components/GamePage.vue";
import PageHeader from "@/components/Header.vue";
import BoardSection from "@/components/BoardSection.vue";
import HistoryList from "@/components/HistoryList.vue"
import LoginPopup from "@/components/LoginPopup";
import Popup from "@/components/Popup";
import RegisterPopup from "@/components/RegisterPopup";
import ProfilePopUp from "@/components/ProfilePopUp.vue";
export default {
  name: "HomeView",
  components: {
    Layout,
    Banner,
    GamePage,
    HistoryList,
    BoardSection,
    PageHeader,
    LoginPopup,
    Popup,
    RegisterPopup,
    ProfilePopUp,
  },
  mixins: [ui],
  data() {
    return {

      };
  },
  computed: {
    ...mapGetters("auth", ["authenticated", "profile"]),
  },
  methods: {
    ...mapActions("auth", {
      authGetProfile: "getProfile",
    }),

  },

  mounted() {
    if (Boolean(window.PusherChannel)) {
      window.PusherChannel.bind("deposit", (e) => {
        this.authGetProfile();
      });
      window.PusherChannel.bind("withdraw", (e) => {
        this.authGetProfile();
      });
    }
  },
};
</script>
