export default {
    methods: {
        dateTime(val) {
            //  May 23, 1996, 04:35PM
            if (!val) {
                return ''
            }
            var date = new Date(val);
            return date.toLocaleDateString('en-us', { year: "numeric", month: "short", day: "2-digit", hour: '2-digit', minute: '2-digit' })
        },
        dateOnly(val) {
            //  May 23, 1996
            if (!val) {
                return ''
            }
            var date = new Date(val);
            return date.toLocaleDateString('en-us', { year: "numeric", month: "short", day: "2-digit" })

        },
        timeOnly(val) {
            //  04:35:01 PM
            if (!val) {
                return ''
            }
            var date = new Date(val);
            return date.toLocaleTimeString('en-us', { hour: '2-digit', minute: '2-digit', second: '2-digit' })
        },
        dateYmD(val, withTime) {
            const dateTime = new Date(val);
            const year = dateTime.getFullYear();
            const month = String(dateTime.getMonth() + 1).padStart(2, '0');
            const day = String(dateTime.getDate()).padStart(2, '0');
            const hours = String(dateTime.getHours()).padStart(2, '0');
            const minutes = String(dateTime.getMinutes()).padStart(2, '0');
            const seconds = String(dateTime.getSeconds()).padStart(2, '0');

            return `${year}-${month}-${day}` + (withTime ? ` ${hours}:${minutes}:${seconds}` : '');
        },
        firstDateOftheMonth() {
            var currentDate = new Date();
            currentDate.setDate(1);
            var year = currentDate.getFullYear();
            var month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
            var firstDayOfMonth = year + '-' + month + '-01';
            return firstDayOfMonth;
        },
        oneWeekAgo() {
            const today = new Date();
            const sevenDaysAgo = new Date(today);
            sevenDaysAgo.setDate(today.getDate() - 7);
            return sevenDaysAgo;
        },
        getOneYearAgo() {
            const currentDate = new Date();
            const yearOneYearAgo = currentDate.getFullYear() - 1;
            const oneYearAgoDate = new Date(yearOneYearAgo, currentDate.getMonth(), currentDate.getDate());
            return oneYearAgoDate;
        },
        monthInWord(val) {
            const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
            return months[val]
        },
        getDaysInMonth(year, month) {
            month = month;
            var date = new Date(year, month + 1, 0);
            return date.getDate();
        },
        getTimeDifference(date1, date2) {
            const timeDifference = Math.abs(date2.getTime() - date1.getTime());
            const minutes = Math.floor(timeDifference / 60000); // 1 min = 60,000 ms
            const seconds = Math.floor((timeDifference % 60000) / 1000); // 1 sec = 1,000 ms
            const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
            const formattedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;

            return `${formattedMinutes}:${formattedSeconds}`;
        },
        getNextOrPreviousMonth(date, direction) {
            // Parse the input date
            const inputDate = new Date(date);

            // Calculate the next or previous month
            let targetMonth;
            if (direction === "next") {
                targetMonth = inputDate.getMonth() + 1;
                if (targetMonth > 11) {
                    targetMonth = 0;
                    inputDate.setFullYear(inputDate.getFullYear() + 1);
                }
            } else if (direction === "previous") {
                targetMonth = inputDate.getMonth() - 1;
                if (targetMonth < 0) {
                    targetMonth = 11;
                    inputDate.setFullYear(inputDate.getFullYear() - 1);
                }
            } else {
                throw new Error("Invalid direction. Use 'next' or 'previous'.");
            }

            // Set the target month and return the date
            inputDate.setMonth(targetMonth);
            return inputDate;
        }
    }
}