import $api from '../api'

export const actions = {
  getList({ rootGetters, dispatch }, pl) {
    return new Promise(function (resolve) {
      $api
        .get(
          `${rootGetters["auth/role"]}/tickets?${pl.page_no ? 'page=' + pl.page_no : ''}${
            pl.limit ? '&limit=' + pl.limit : '&limit=50'
          }`,
          {
            headers: {
              Authorization: rootGetters['auth/bearer_token'],
            },
          },
        )
        .then(function (res) {
          if (res.status == 200) {
            resolve(res.data.data)
          }
        })
        .catch(function (err) {
          if (err.response.status == 401) {
            dispatch('auth/logoutUser', {}, { root: true }).then(() => {})
          }
        })
    })
  },
  createTicket({ rootGetters, dispatch }, pl) {
    return new Promise(function (resolve) {
      $api
        .post(`${rootGetters["auth/role"]}/ticket`, pl, {
          headers: {
            Authorization: rootGetters['auth/bearer_token'],
          },
        })
        .then(function (res) {
          if (res.status == 200) {
            resolve(res)
          }
        })
        .catch(function (err) {
          if (err.response.status == 401) {
            dispatch('auth/logoutUser', {}, { root: true }).then(() => {})
          } else {
            resolve(err.response)
          }
        })
    })
  },
  view({ rootGetters, dispatch }, id) {
    return new Promise(function (resolve) {
        $api
            .get(`${rootGetters["auth/role"]}/ticket/${id}`, {
                headers: {
                    Authorization: rootGetters["auth/bearer_token"],
                },
            })
            .then(function (res) {
                if (res.status == 200) {
                    resolve(res.data.data);
                }
            })
            .catch(function (err) {
                if (err.response.status == 401) {
                    dispatch("auth/logoutUser", {}, { root: true }).then(() => { });
                }
            });
    });
},
commentCreate({ rootGetters, dispatch }, pl) {
    return new Promise(function(resolve) {
        $api
            .post(`${rootGetters["auth/role"]}/ticket/comment/${pl.ticket_id}`, pl.form_data, {
                headers: {
                    Authorization: rootGetters["auth/bearer_token"],
                },
            })
            .then(function(res) {
                if (res.status == 200) {
                    resolve({
                        success: true,
                        data: res.data
                    });
                }
            })
            .catch(function(err) {
                if (err.response.status == 401) {
                    dispatch("auth/logoutUser", {}, { root: true }).then(() => {});
                }
                if (err.response.status == 422) {
                    resolve({
                        success: false,
                        data: err.response.data
                    });
                } 
                if (err.response.status == 400) {
                    resolve({
                        success: false,
                        data: err.response.data
                    });
                } 

            });
    });
},
}
