<template>
    <router-view ></router-view>
</template>
<script>
import ui from '@/mixins/ui'
import {mapActions,mapGetters} from 'vuex'
export default {
    mixins: [ui],
     data() {
        return {
            polling: null,
        };
    },
    computed: {
        ...mapGetters('auth', ['authenticated']),
    },
    watch: {
        authenticated(newVal) {
            if (!newVal) {
                this.stopPolling();
            } else {
                this.startPolling();
            }
        },
    },
    methods: {
        async profileInterval() {
            await this.$store.dispatch('auth/getProfile');
        },
        startPolling() {
            this.polling = setInterval(this.profileInterval, 10000);
        },
        stopPolling() {
            clearInterval(this.polling);
        },
    },
    mounted() {
        var vm = this;

        if(vm.authenticated)  {
            // vm.$store.dispatch('auth/getProfile');
            vm.startPolling();
        }
        if ($cookies.get('locale')) {
            vm.$i18n.locale = $cookies.get('locale')
        }   

        window.addEventListener('message', function (e) {
            if (e.origin === window.origin) {
                if (e.data.event == 'close-game' && !window.opener) {
                    vm.$store.dispatch('auth/getProfile');
                    vm.$store.commit('closeWindow', e.data.name);
                }
                if (e.data.event == 'open-game' && !window.opener) {
                    // vm.$store.commit("setPopups");
                    vm.$store.commit('addWindow', {
                        popup: e.data.name,
                        gameType: e.data.gameType,
                    });
                }
            }
        });
    }
};
</script>