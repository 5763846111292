<template>
  <section class="board-section w-ba">
    <div class="container container mx-auto" style="max-width: 1300px;">
      <h2>{{ $t('bet list') }}</h2>
      <div class="content mt-2">
        <div class="bet-list">
          <ul class="bet-list-desktop">
            <li class="flex transaction-header-list justify-start">
              <div>#</div>
              <div>{{ $t('date') }}</div>
              <div>{{ $t('game') }}</div>
              <div>{{ $t('type') }}</div>
              <div>{{ $t('provider') }}</div>
              <div>{{ $t('amount') }}</div>
              <div>{{ $t('win amount') }}</div>
              <div>{{ $t('status') }}</div>
            </li>
            <li
              v-for="(data, index) in bet_data.list"
              :key="index"
              class="flex items-start justify-center"
            >
              <div class="user">
                <span>
                  {{
                    index +
                    1 +
                    (this.bet_data.current_page - 1) * this.bet_data.per_page
                  }}
                </span>
              </div>
              <div class="date">
                <span>{{ data.created_at }}</span>
              </div>
              <div class="">
                <span>{{ $t(data.game) }}</span>
              </div>
              <div class="">
                <span>{{ $t(data.type) }}</span>
              </div>
              <div class="">
                <span>{{ $t(data.provider) }}</span>
              </div>
              <div class="">
                <span>{{ numberString(data.amount) }}</span>
              </div>
              <div class="">
                <span>{{ numberString(data.win_amount) }}</span>
              </div>
              <div class="status">
                <span
                  :class="`${
                    data.status == 'win'
                      ? 'win'
                      : data.status == 'lose'
                      ? 'lose'
                      : ''
                  }`"
                >
                  {{ data.status }}
                </span>
              </div>
            </li>
            <li
              class="text-center pt-24 block bet-list-desktop"
              v-if="bet_data.list.length <= 0"
            >
              {{ $t('there is no history') }}
            </li>
          </ul>
        </div>
        <table class="text-white table-bordered mt-3 mb-2">
          <tbody>
            <tr
              v-for="(data, index) in bet_data.list"
              :key="index"
              style="border-bottom: solid 1px rgba(255, 255, 255, 0.1);"
            >
              <td class="bet-list-mobile py-2">
                <div>
                  <span class="label mr-2">{{ $t('type') }}:</span>
                  {{ $t(data.type) }}
                </div>
                <div>
                  <span class="label mr-2">{{ $t('date') }}:</span>
                  {{ data.created_at }}
                </div>
                <div>
                  <span class="label mr-2">{{ $t('game') }}:</span>

                  {{ $t(data.game) }}
                </div>
                <div>
                  <span class="label mr-2">{{ $t('vendor') }}:</span>
                  {{ $t(data.vendor) }}
                </div>
                <div>
                  <span class="label mr-2">{{ $t('amount') }}:</span>
                  {{ numberString(Math.round(data.amount)) }}
                </div>
                <div>
                  <span class="label mr-2">{{ $t('win amount') }}:</span>
                  {{ numberString(Math.round(data.win_amount)) }}
                </div>
                <div>
                  <span class="label mr-2">{{ $t('status') }}:</span>
                  <span
                    class="status"
                    :class="`${
                      data.status == 'win'
                        ? 'win'
                        : data.status == 'lose'
                        ? 'lose'
                        : ''
                    }`"
                  >
                    {{ $t(data.status) }}
                  </span>
                </div>
              </td>
            </tr>
            <td
              class="text-center pt-24 block bet-list-mobile"
              v-if="bet_data.list.length <= 0"
            >
              {{ $t('there is no history') }}
            </td>
          </tbody>
        </table>
        <div class="mt-10">
          <Pagination
            :data="bet_data"
            @emitPage="getBetList"
            v-if="bet_data.list.length > 0"
          />
        </div>
      </div>
    </div>
    <div class="container container mx-auto mt-5" style="max-width: 1300px;">
      <h2>{{ $t('points list') }}</h2>
      <div class="content mt-2">
        <div class="points-list">
          <ul class="points-list-desktop">
            <li class="flex transaction-header-list justify-start">
              <div>#</div>
              <div>{{ $t('date') }}</div>
              <div>{{ $t('type') }}</div>
              <div>{{ $t('initial value') }}</div>
              <div>{{ $t('updated value') }}</div>
              <div>{{ $t('amount') }}</div>
            </li>
            <li
              v-for="(data, index) in points_data.list"
              :key="index"
              class="flex items-start justify-center"
            >
              <div class="user">
                <span>
                  {{
                    index +
                    1 +
                    (this.points_data.current_page - 1) *
                      this.points_data.per_page
                  }}
                </span>
              </div>
              <div class="date">
                <span>{{ data.created_at }}</span>
              </div>
              <div class="">
                <span>{{ $t(data.type) }}</span>
              </div>
              <div class="">
                <span>{{ numberString(Math.floor(data.updated_value)) }}</span>
              </div>
              <div class="">
                <span>{{ numberString(Math.floor(data.initial_value)) }}</span>
              </div>
              <div class="">
                <span>{{ numberString(Math.floor(data.amount)) }}</span>
              </div>
            </li>
            <li
              class="text-center pt-24 block points-list-desktop"
              v-if="points_data.list.length <= 0"
            >
              {{ $t('there is no history') }}
            </li>
          </ul>
        </div>
        <table class="text-white table-bordered mt-3 mb-2">
          <tbody>
            <tr
              v-for="(data, index) in points_data.list"
              :key="index"
              style="border-bottom: solid 1px rgba(255, 255, 255, 0.1);"
            >
              <td class="points-list-mobile py-2">
                <div>
                  <span class="label mr-2">{{ $t('type') }}:</span>
                  {{ $t(data.type) }}
                </div>
                <div>
                  <span class="label mr-2">{{ $t('date') }}:</span>
                  {{ data.created_at }}
                </div>
                <div>
                  <span class="label mr-2">{{ $t('initial value') }}:</span>

                  {{ numberString(Math.floor(data.initial_value)) }}
                </div>
                <div>
                  <span class="label mr-2">{{ $t('updated value') }}:</span>
                  {{ numberString(Math.floor(data.updated_value)) }}
                </div>
                <div>
                  <span class="label mr-2">{{ $t('amount') }}:</span>
                  {{ numberString(Math.floor(data.amount)) }}
                </div>
              </td>
            </tr>
            <td
              class="text-center pt-24 block points-list-mobile"
              v-if="points_data.list.length <= 0"
            >
              {{ $t('there is no history') }}
            </td>
          </tbody>
        </table>
        <div class="mt-10">
          <Pagination
            :data="points_data"
            @emitPage="getPointsList"
            v-if="points_data.list.length > 0"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions,mapGetters } from 'vuex'
import number from '@/mixins/number'
import Pagination from '@/components/Pagination'
export default {
  name: 'History List',
  components: { Pagination },
  data() {
    return {
      bet_data: {
        list: [],
        links: [],
        current_page: 1,
        per_page: 1,
        last_page: 1,
        total: 0,
      },
      points_data: {
        list: [],
        links: [],
        current_page: 1,
        per_page: 1,
        last_page: 1,
        total: 0,
      },
    }
  },
  mixins: [number],
  computed: {
    ...mapGetters("auth", ["authenticated", "profile", 'role']),
  },
 watch: {
    'authenticated' (loggedIn) {
      if(loggedIn) {
        this.getBetList()
        if(this.profile.role=='player') {
          this.getPointsList()  
        }
      }
    }
  },
  methods: {
    ...mapActions('bet', {
      betList: 'getList',
    }),
    ...mapActions('points', {
      pointsList: 'getList',
    }),
    async getBetList(p) {
      var pl = {
        page_no: p ? p : 1,
        limit: 10,
      }
      const data = await this.betList(pl)
      this.bet_data.list = data.data
      this.bet_data.links = data.links
      this.bet_data.current_page = data.current_page
      this.bet_data.per_page = data.per_page
      this.bet_data.last_page = data.last_page
    },
    async getPointsList(p) {
      var pl = {
        page_no: p ? p : 1,
        limit: 10,
      }
      const data = await this.pointsList(pl)
      this.points_data.list = data.data
      this.points_data.links = data.links
      this.points_data.current_page = data.current_page
      this.points_data.per_page = data.per_page
      this.points_data.last_page = data.last_page
    },
  },
  mounted() {
    if(this.authenticated) {
      this.getBetList()
      if(this.role == 'player') {
        this.getPointsList();
      }
    }
  },
}
</script>

<style></style>
