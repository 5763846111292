import { createStore } from 'vuex'

import modules from './modules'


const store = createStore({
  state: {
      gameType: $cookies.get("gameType") || null,
      popups: $cookies.get("popups") || [],
      key: 0,
      launching_game: false
  },
  mutations: {
        setPopups(state) {
            state.popups = $cookies.get("popups") || [];
        },
        addWindow(state, game) {
            if (!state.popups.includes(game.popup)) {
                state.popups.push(game.popup);
                $cookies.set("popups", state.popups);
                state.gameType = game.gameType
                $cookies.set("gameType", state.gameType);
            }
        },
        closeWindow(state, windowName) {
            state.popups.forEach((popupName, index) => {
                if (windowName === popupName) {
                    state.popups.splice(index, 1);
                    return;
                }
            });
            if (state.popups.length > 0) {
                $cookies.set("popups", state.popups);
                $cookies.set("gameType", state.gameType);
            } else {
                state.popups = [];
                $cookies.remove("popups");
                state.gameType = null;
                $cookies.remove("gameType");
            }
        },
        closeAllWindow(state) {
            state.popups = [];
            $cookies.remove("popups");
            state.gameType = null;
            $cookies.remove("gameType");
        },
        triggerAnything(state) {
            state.key += 1;
        },
        triggerLaunching(state, launching) {
            state.launching_game = launching;
        },
    },
  modules,
  // Enable strict mode in development to get a warning
  // when mutating state outside of a mutation.
  // https://vuex.vuejs.org/guide/strict.html
  strict: process.env.NODE_ENV !== 'production',
})

export default store

