<template>
  <div class="modal" id="viewTicket">
    <div class="modal-content modal-panel event-notice relative">
      <div class="modal-header">
        <button
          class="modal-close-btn w-ba"
          @click="close"
        ></button>
        <div class="modal-banner flex flex-col justify-center items-center">
          <a href="javascript:void(0)" class="modal-logo flex justify-center">
            <img src="@/assets/img/dream.png" alt="Dream Logo" />
          </a>
        </div>
      </div>
      <div class="modal-body mt-5">
        <div class="content w-ba pt-5">
          <h3 class="font-bold text-lg">{{ data.subject }}</h3>
          <div style="font-size: 0.5rem">
            <span class="mr-1">{{ data.customer.username }} >></span>
            <span class="">{{ data.created_at }}</span>
          </div>
          <div class="mt-5">
            <div v-html="data.content"></div>
          </div>
          <div class="mt-5">
            <h3>Comment ({{ data.comments.length }})</h3>
            <ul class="mt-2">
              <li
                v-for="(comment, index) in data.comments"
                :key="index"
                class="mt-2 rounded-sm p-1 px-2 wrapper"
              >
                <div style="font-size: 0.5rem">
                  <span class="mr-1">
                    Reply by {{ comment.user.username }} >>
                  </span>
                  <span class="">{{ comment.created_at }}</span>
                </div>
                <div v-html="comment.content"></div>
              </li>
            </ul>
          </div>
          <div class="input-group">
            <div
              class="mt-8"
              style="background: #222222; height: 100%; width: 100% !important"
              v-if="data.status == 'open'"
            >
              <QuillEditor
                theme="snow"
                :options="editorOptions"
                contentType="html"
                ref="myEditor"
                class="custom-quill-editor"
                v-model:content="reply.content"
              />
            </div>
          </div>
          <div class="form-footer dflex-ac-jc">
            <button
              class="btn-yellow"
              @click="closePopup('viewTicket')"
              style="width: 50%"
            >
              <span style="vertical-align: inherit">   {{ $t("return") }}</span>
            </button>
            <button
              class="btn-red"
              @click="submitComment(data._id)"
              style="width: 50%"
              v-if="data.status == 'open'"
            >
                 {{ $t("submit") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ui from "@/mixins/ui";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Ticket View",
  components: {},
  mixins: [ui],
  data() {
    return {
      editorContent: "",
      editorOptions: {
        // Vue Quill Editor configuration options
        placeholder: "내용 입력...",
        modules: {
          toolbar: [
            [{ color: [] }, { background: [] }],
            ["bold", "italic", "underline", "strike"],
            ["blockquote", "code-block"],
            [{ header: 1 }, { header: 2 }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ script: "sub" }, { script: "super" }],
            [{ indent: "-1" }, { indent: "+1" }],
            [{ size: ["small", false, "large", "huge"] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],

            [{ font: [] }],
            [{ align: [] }],
            ["clean"],
            ["image"],
          ],
        },
      },
    };
  },
  props: {
    data: {
      type: Object,
    },
    reply: {
      type: Object,
    },
  },
  computed: {
    ...mapGetters("auth", ["authenticated", "profile"]),
  },
  methods: {
    ...mapActions("auth", {
      authGetProfile: "getProfile",
    }),
    submitComment(id) {
      this.$emit("submitComment", id);
    },
    close() {
        this.closePopup('viewTicket')
        this.$emit('reset')
    }
  },
  mounted() {},
};
</script>
<style scoped>
.wrapper {
  background-color: rgba(34, 34, 34, 0.5);
  border: solid 1px rgba(255, 255, 255, 0.15);
  border-radius: 10px;
}
</style>
