<template>
	<main class="page-content w-ba">
		<section class="toggle-section toggle-3">
			<div class="container mx-auto dflex-ac-ja">
				<button
					class="toggle-btn casino-toggle"
					:class="active == 'liveCasinoGames' ? 'active' : ''"
					data-target="casino"
					@click="setActiveTab('liveCasinoGames')"
				>
					<div class="btn-panel dflex-ac-js w-ba ml-auto cursor-pointer">
						<div class="icon-panel w-ba dflex-ac-jc">
							<img src="@/assets/img/casino-icon.png" />
			
						</div>
						<span class="category">   {{ $t('live casino') }}</span>

						<font-awesome-icon
							class="fa-solid fa-caret-down indicator ml-md-3 ml-1"
							icon="fa-solid fa-caret-down"
						/>
					</div>
				</button>

				<button
					class="toggle-btn mini-toggle disabled cursor-not-allowed"
					data-target="mini"
					:class="active == 'mini-games' ? 'active' : ''"
					@click="setActiveTab('mini-games')"
					disabled
				>
					<div class="btn-panel dflex-ac-je w-ba mr-auto disabled cursor-not-allowed" >
						<font-awesome-icon
							class="fa-solid fa-caret-down indicator ml-md-3 ml-1"
							icon="fa-solid fa-caret-down"
						/>
						<span class="category">{{$t('mini game')}}</span>
					</div>
				</button>

				<button
					class="toggle-btn slot-toggle cursor-pointer"
					data-target="slot"
					:class="active == 'slotGames' ? 'active' : ''"
					@click="setActiveTab('slotGames')"
				>
					<div class="btn-panel dflex-ac-je w-ba mr-auto">
						<font-awesome-icon
							class="fa-solid fa-caret-down indicator ml-md-3 ml-1"
							icon="fa-solid fa-caret-down"
						/>
						<span class="category">{{$t('slot game')}}</span>
						<div class="icon-panel w-ba dflex-ac-jc">
							<img src="@/assets/img/slot-icon.png" />
						</div>
					</div>
				</button>
			</div>
		</section>
		<section class="game-provider">
			<div class="container mx-auto">
				<!-- <div class="casino-section sc-section active"> -->
				<div
					class="casino-section sc-section container mx-auto px-3"
					v-if="active == 'liveCasinoGames'"
				>
					<div class="dflex-ac-jc items-start">
						<a
							href="javascript:void(0);"
							class="sc-btn w-ba"
							:class="{disabled: !data.enabled}"
							v-for="data in $store.state['game']['mamahotGameProviders']"
							:key="data.id"
						>
							<div class="g-panel wba">
								<div class="g-cont">
									<img
										:src="require('@/assets/img/mamahot_thumbnails/' + data.game_thumbnail)"
										alt=""
										class="g-img"
									/>
									<button
										type="button"
										class="play-btn btn-yellow"
										:disabled="loading"
										@click="initMamahot(data)"
									>
										<span>게임입장</span>
									</button>
								</div>
								<div class="g-footer w-ba">
									<div class="g-logo dflex-ac-jc w-ba">
										<img
											class="icon-img"
											:src="require('@/assets/img/' + data.logo)"
											alt=""
										/>    
										
									</div>
								</div>
								<div class="g-info text-center">
									<span class="g-name">{{ data.name }} </span>
									<span class="en-text">
										 
										{{ data.title }}
									</span>
								</div>
								<div class="glass"></div> 
								<!--  -->
								<img v-if="loading && data.id == currentGameLoading" class="small__loader"
									src="@/assets/img/small-loader.svg"
									alt="" />
							</div>
						</a>
					</div>
				</div>

				<!-- </div> -->

				<div
					class="mini-section sc-section container mx-auto px-3"
					v-if="active == 'mini-games'"
				>
					<div class="dflex-ac-jc items-start">
						<a
							href="javascript:void(0);"
							class="sc-btn w-ba"
							:class="{disabled: !data.enabled}"
							v-for="data in mini_game.list"
							:key="data.id"
						>
							<div class="g-panel wba">
								<div class="g-cont">
									<img
										:src="require('@/assets/img/' + data.game_thumbnail)"
										alt=""
										class="g-img"
									/>
									<button type="button" class="play-btn btn-yellow">
										게임입장
									</button>
								</div>
								<div class="g-footer w-ba">
									<div class="g-logo dflex-ac-jc w-ba">
										<img
											class="icon-img"
											:src="require('@/assets/img/' + data.logo)"
											alt=""
										/>
									</div>
								</div>
								<div class="g-info text-center">
									<span class="g-name">{{ data.name }}</span>
									<span class="en-text">{{ data.title }}</span>
								</div>
								<div class="glass"></div>
							</div>
						</a>
					</div>
				</div>

				<div
					class="slot-section sc-section container mx-auto px-3"
					v-if="active == 'slotGames'"
				>
					<div class="dflex-ac-jc items-start">
						<a
							href="javascript:void(0);"
							class="sc-btn w-ba"
							v-for="data in $store.state['game']['honorLinkSlot']"
							:key="data.id"
						>
							<div class="g-panel wba">
								<div class="g-cont">
									<!-- <img
										:src="require('@/assets/img/' + data.game_thumbnail)"
										alt=""
										class="g-img"
									/> -->
									 <img class="g-img g-img-cover" :src="require('@/assets/img/honorlink_slot_thumbnails/' + data.game_thumbnail)" alt="" />

									<button
										type="button"
										class="play-btn btn-yellow"
										@click.prevent="handleSlotGame(data)"
									>
										게임입장
									</button>
								</div>
								<div class="g-footer w-ba">
									<div class="g-logo dflex-ac-jc w-ba">
										<img
											class="icon-img"
											:src="require('@/assets/img/' + data.logo)"
											alt=""
										/>
									</div>
								</div>
								<div class="g-info text-center">
									<span class="g-name">{{ data.name }}</span>
									<span class="en-text">{{ data.title }}</span>
								</div>
								<div class="glass"></div>
							</div>
						</a>
					</div>
				</div>
			</div>
		</section>
	</main>
	<button class="scroll-top w-ba" style="display: block;" @click="scrollToTop">
		<font-awesome-icon class="fa" icon="fa-solid fa-caret-up" />
	</button>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import ui from '@/mixins/ui';
import Swal from 'sweetalert2'
export default {
	name: 'Main Page',
	data() {
		return {
			loading: false,
			currentGameLoading: null,
			active: 'liveCasinoGames',
		}
	},
	computed: {
		...mapGetters('auth', ['authenticated', 'profile']),
		...mapState('slotGame', {
			slot_game: 'data',
		}),
		...mapState('casinoGame', {
			casino_game: 'data',
		}),
		...mapState('miniGame', {
			mini_game: 'data',
		}),
	},
	mixins: [ui],
	methods: {
		...mapActions('auth', {
			authGetProfile: 'getProfile',
		}),
		...mapActions('slotGame', {
			getSlotGame: 'getList',
			slotGameLaunch: 'getGameLaunch',
		}),
		...mapActions('casinoGame', {
			getCasinoGame: 'getList',
			casinoGameLaunch: 'getGameLaunch',
		}),
		...mapActions('miniGame', {
			getMiniGames: 'getList',
		}),
		...mapActions('game', ['launchMamahot','exitMamahot']),
		async casinoList() {
			await this.getCasinoGame()
		},
		async slotList() {
			await this.getSlotGame()
		},
		async miniGameList() {
			await this.getMiniGames()
		},
		async handleSlotGame(data) {
			if (!this.authenticated) return;

			const pl = {
				game_id: data.id,
				vendor: data.vendor,
			}

			switch (pl.vendor) {
				case 'Dragoon Soft':
				case 'Blueprint Gaming':
				case 'Habanero':
				case 'Mobilots':
				case 'PlayPearls':
				// case 'GameArt':
				// case 'PlayStar':
				// case 'Thunderkick':
				// case 'Wazdan':
				// case 'EVOPLAY':
					this.$router.replace({
						path: `/game/slot`,
						query: {
							vendor: data.vendor,
							vendorName: data.name,
							game_id: data.id,
							prevPage: this.active
						},
					})

					break;
				case 'redtiger':
				case 'PragmaticPlay':
				case 'CQ9':
				case 'netent':
				case "MicroGaming Plus Slo":
				case "dreamtech":
				case "evoplay":
				case "PG Soft":
				case "Blueprint Gaming":
				case "Relax Gaming":
				case "spinomenal":
				case "Wazdan":
				case "PlayStar":
				case "GameArt":
				case "Thunderkick":
				case "dragoonsoft":
				case "1X2 Gaming":
				case "bfgames":
				case "platipus":
				case "retrogames":
				case "fils":
				case "platingaming":
				case "eagaming":
					this.$router.replace({
						path: `/game/slot`,
						query: {
							vendor: data.vendor,
							vendorName: data.name,
							game_id: "honorlink_slots",
							prevPage: this.active
						},
					})
					break;
				default:
				 	await this.initHonorlink(data)
					break;
			}
		},
		async handleCasinoGame(data) {
			const pl = {
				game_id: data.id,
				vendor: data.vendor,
			}

			switch (pl.game_id) {
				case 'dgcasino':
				case 'wmcasino':
				case '0':
				case 'bota':
				case '101':
				case 'betgame_casino':
				case 'skywind_casino':
					this.$router.push({
						path: '/game/casino',
						query: {
							vendor: pl.vendor,
							game_id: pl.game_id,
						},
					})
					break
				default:
					await this.initHonorlink(data)
			}
		},
		async initMamahot(game) { 
			if(!this.authenticated || !game.enabled) return;
			this.loading = true;
			this.currentGameLoading = game.id;

			await this.exitMamahot();
			if(game.is_honorlink) {
				this.handleCasinoGame(game);
				return;
			}
			var pl = {
				provider_code: game.provider_code,
				lang: this.$i18n.locale == 'kr' ? 'ko' : 'en',
			}
			const res = await this.$store.dispatch('game/launchMamahot', pl);
			if (res.status == 200) {
				if (res.data.status == "success") {
					this.openGameWindow(res.data.data.url, game.provider_code, game.vendor);
					this.authGetProfile();
				} else {
					Swal.fire({
						icon: 'error',
						html: res.data.message,
						confirmButtonText: this.$t('ok'),
						showConfirmButton: true,
						color: '#fff',
					});
				}
			} else if (res.status == 401) {
				this.$router.replace({ path: '/' }).catch(() => { });
			} else {
				console.log(res)
				Swal.fire({
					icon: 'error',
					html: this.$t(res.data?.message),
					confirmButtonText: this.$t('ok'),
					showConfirmButton: true,
				});
			}
			this.loading = false;
			this.currentGameLoading = null;
		},
		async initHonorlink(game) {
			this.loading = true;
			this.currentGameLoading = game.id;
			const res = await this.$store.dispatch('game/launchHonorlink', {
				game_id: game.id,
				vendor: game.vendor,
				lang: "ko",
			}); 
			if (res.status == 200) {
				this.openGameWindow(res.data.data.url, game.id,  game.vendor)
			} else if (res.status == 401) {
				this.$router.replace({ path: '/' }).catch(() => { });
			} else {
				Swal.fire({
					icon: 'error',
					title: this.$t(res.data.message?res.data.message.toLowerCase():'An error occured :('),
					confirmButtonText: this.$t('ok'),
					showConfirmButton: true,
					timer: 0
				});
			}
			this.loading = false;
			this.currentGameLoading = null;
		},
		setActiveTab(tab) {
			this.active = tab
			this.$router.replace({ query: { active: tab } })
		},

		handleScroll() {
			this.showButton = window.scrollY > 0
		},
		scrollToTop() {
			window.scrollTo({ top: 0, behavior: 'smooth' })
		},
	},
	mounted() {
		this.active = this.$route.query.active || 'liveCasinoGames'
		// this.casinoList()
		// this.slotList()
		// this.miniGameList()
		
	},
	beforeUnmount() {
		window.removeEventListener('scroll', this.handleScroll)
	},
}
</script>

<style></style>
