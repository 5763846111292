import $api from "../api";
import router from "../../router/index";
import $cookies from "vue-cookies";
import Swal from "sweetalert2";
import Pusher from "pusher-js";
const pusher = new Pusher(process.env.VUE_APP_PUSHER_APP_KEY, {
  cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
});

export const state = {
  token: $cookies.get("token"),
  type: 1,
  id: 1,
  profile: {
    loading: true,
    routeTo: null,
    username: null,
    status: null,
    last_login_at: null,
    last_login_ip: null,
    balance: 0,
    points: 0,
    withdraw: {
      pin: false,
      password: false,
    },
    ticket: {
      unread: 0,
    },
    transfer_in_game: null,
    role: null
  },
};

export const actions = {
  loginUser({ commit, dispatch }, pl) {
    return new Promise(function (resolve, reject) {
      $api
        .post("auth/player/login", pl)
        .then(async function (res) {
          if (res.status === 200) {
            commit("setToken", res.data.data);
            resolve({
              success: true,
              message: "",
            });
          }
        })
        .catch(function (err) {
            resolve(err.response)
        //   var err_msg;
        //   if (err.response) {
        //     err_msg = err.response.data.message
        //       ? err.response.data.message
        //       : "Invalid Credentials";
        //   }
        //   resolve({
        //     success: false,
        //     message: err_msg,
        //   });
        //   reject(err);
        });
    });
  },
  logoutUser({ commit, getters }) {
    return new Promise(function (resolve) {
      $api
        .get("auth/logout", {
          headers: { Authorization: getters.bearer_token },
        })
        .then(function () {
          commit("destroyAuth");
          resolve(true);
        })
        .catch(function () {
          commit("destroyAuth");
          resolve(true);
        });
    });
  },
  getProfile({ commit, getters }) {
    return new Promise(function (resolve) {
      $api
        .get("auth/profile", {
          headers: { Authorization: getters.bearer_token },
        })
        .then(function (res) {
          if (res.status === 200) {
            commit("setProfile", res.data.data);
          }
          resolve(true);
        })
        .catch(function (err) {
          if (err.response.status == 401) {
            commit("destroyAuth");
          }
          resolve(false);
        });
    });
  },
  changePass({ rootGetters, dispatch }, pl) {
    return new Promise(function (resolve) {
      $api
        .post("/player/change-password", pl, {
          headers: {
            Authorization: rootGetters["auth/bearer_token"],
          },
        })
        .then(function (res) {
          resolve(res.data);
          //   if (res.status == 200) {
          //     resolve(res.data);
          //   }
          // if (res.status == 200) {
          //     Swal.fire({
          //         icon: "success",
          //         title: "Updating succeed",
          //         text: res.data.message,
          //         showCancelButton: true,
          //         confirmButtonColor: "#f46a6a",
          //     });

          // }else{

          // }
        })
        .catch(function (err) {
          if (err.response.status == 401) {
            dispatch("auth/logoutUser", {}, { root: true }).then(() => {});
          }
          if (err.response.status == 422) {
            console.log(err);
            resolve(err.response);
          }
        });
    });
  },
  registerUser({ commit }, pl) {
    return new Promise(function (resolve, reject) {
      $api
        .post("player/register", pl)
        .then(function (res) {
          if (res.status === 201) {
            console.log(res);
            resolve({
              success: true,
              message: "",
            });
          }
        })
        .catch(function (err) {
          var err_msg;
          if (err.response) {
            err_msg = err.response.data.message
              ? err.response.data.message
              : "Error Occured";
          }
          resolve({
            success: false,
            message: err_msg,
          });
          reject(err);
        });
    });
  },
  updateProfile({ rootGetters, dispatch }, pl) {
    return new Promise(function (resolve) {
      $api
        .post("auth/profile", pl, {
          headers: {
            Authorization: rootGetters["auth/bearer_token"],
          },
        })
        .then(function (res) {
          if (res.status == 200) {
            resolve(res);
          }
        })

        .catch(function (err) {
          if (err.response.status == 401) {
            dispatch("auth/logut", {}, { root: true }).then(() => {});
          }
          if (err.response.status == 422) {
            Swal.fire({
              icon: "error",
              title: "Failed",
              text: err.response.data.message,
              confirmButtonColor: "#f46a6a",
            });
            console.log(err);
            resolve(err.response);
          }
        });
    });
  },
};

export const mutations = {
  triggerLoader(state, value) {
    state.profile.loading = Boolean(value);
    state.profile.routeTo = value ? value : null;
  },
  setToken(state, data) {
    var token = data.access_token; //`${data.token_type} ${data.access_token}`;
    $cookies.set("token", token);
    state.profile.role = data.role;
    state.token = token;
    window.PusherChannel = pusher.subscribe(token.split("|")[0]);
  },
  setProfile(state, data) {
    if (!Boolean(window.PusherChannel)) {
      window.PusherChannel = pusher.subscribe(state.token.split("|")[0]);
    }
    state.profile.username = data.username;
    state.profile.status = data.status;
    state.profile.last_login_at = data.last_login_at;
    state.profile.last_login_ip = data.last_login_ip;
    if(data.role=='player') {
      state.profile.balance = data.balance;
      state.profile.points = data.points;
    } else {
      state.profile.balance = data['agent.assets.balance'];
      state.profile.points = data['agent.assets.points'];
    }
    state.profile.withdraw.pin = data.withdraw.pin;
    state.profile.withdraw.password = data.withdraw.password;
    state.profile.ticket.unread = data.ticket.unread;
    state.profile.transfer_in_game = data.transfer_in_game;
    state.profile.role = data.role;
  },
  destroyAuth(state) {
    $cookies.remove("token");
    if (state.token) {
      pusher.unsubscribe(state.token.split("|")[0]);
    }
    state.token = null;
    state.profile.username = null;
    state.profile.status = null;
    state.profile.last_login_at = null;
    state.profile.last_login_ip = null;
    state.profile.balance = 0;
    state.profile.points = 0;
    state.profile.withdraw.pin = false;
    state.profile.withdraw.password = false;
    state.profile.ticket.unread = 0;
    state.profile.transfer_in_game = null;
    state.profile.role = null;
    router.replace({ path: "/" }).catch(() => { });
  },
};
export const getters = {
  authenticated(state) {
    return state.token != null ;
  },
  bearer_token(state) {
    return "Bearer " + state.token;
  },
  pusher_channel(state) {
    return state.token.split("|")[0];
  },
  profile(state) {
    return state.profile;
  },
  role(state) {
    return state.profile.role;
  },
};
