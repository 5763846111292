<template>
  <div class="modal" id="openLoginModal">
    <div class="modal-content modal-panel relative">
      <div class="modal-header">
        <button
          class="modal-close-btn w-ba"
          @click="
            closePopup('openLoginModal');
            reset();
          "
        ></button>
        <div class="modal-banner flex flex-col justify-center items-center">
          <a href="javascript:void(0)" class="modal-logo flex justify-center">
            <img
              :src="logoChange()"
              alt="Dream Logo"
              class="modal-logo"
            />
          </a>
          <div class="modal-title">
            <div class="modal-icon dflex-ac-jc">
              <font-awesome-icon class="" icon="fa-solid fa-door-open" />
            </div>
            <div class="title-panel text-center">
              <span class="title">로그인</span>
              <span class="sub w-mob-title">WELCOME TO CASINO</span>
            </div>
          </div>
        </div>
        <div class="modal-head-panel dflex-ac-jc">
          <span class="modal-title-mob hidden">WELCOME TO CASINO</span>
        </div>
      </div>
      <div class="modal-body mt-5">
        <h2>{{ $t("login") }}</h2>
        <form action="" @submit.prevent="tryToLogIn">
          <div class="form-container pt-5">
            <div class="form-group">
              <div class="infos">
                <div class="input-container">
                  <input
                    type="text"
                    placeholder="아이디"
                    name="login_id"
                    v-model="username"
                    :class="{
                      'is-invalid': submitted && v$.username.$error,
                    }"
                  />
                </div>
              </div>
              <div
                v-for="(item, index) in v$.username.$errors"
                :key="index"
                class="invalid-feedback block uppercase"
              >
                <span v-if="item.$message">{{ $t(item.$message) }}</span>
              </div>
            </div>
            <div class="form-group">
              <div class="infos">
                <div class="input-container" style="display: block">
                  <div class="flex relative">
                    <input
                      type="password"
                      :placeholder="$t('password')"
                      v-model="password"
                      autocomplete="one-time-code"
                      :class="{
                        'is-invalid': submitted && v$.password.$error,
                      }"
                      v-if="!showPassword"
                      class="w-full"
                    />
                    <input
                      :placeholder="$t('password')"
                      v-model="password"
                      :class="{
                        'is-invalid': submitted && v$.password.$error,
                      }"
                      v-if="showPassword"
                      class="w-full"
                    />
                    <font-awesome-icon
                      style="color: #d2b270"
                      class="absolute right-2 top-4"
                      :icon="
                        !showPassword
                          ? 'fa-regular fa-eye'
                          : 'fa-regular fa-eye-slash'
                      "
                      @click="togglePassword"
                    ></font-awesome-icon>
                  </div>
                </div>
              </div>
              <div
                v-for="(item, index) in v$.password.$errors"
                :key="index"
                class="invalid-feedback block uppercase"
              >
                <span v-if="item.$message">{{ $t(item.$message) }}</span>
              </div>
            </div>
          </div>
          <div class="form-footer dflex-ac-jc">
            <button type="submit" class="btn-yellow login-link login-btn">
              로그인
            </button>
            <button
              type="button"
              class="btn-red join-link"
              @click="
                closePopup('openLoginModal');
                openPopup('openRegisterModal');
              "
            >
              회원가입
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import ui from "@/mixins/ui";
import { required, helpers } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import { mapActions } from "vuex";
import Swal from "sweetalert2";
export default {
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      submitted: false,
      username: null,
      password: null,
      showPassword: false,
      site: process.env.VUE_APP_URL,
    };
  },
  validations() {
    return {
      username: {
        required: helpers.withMessage("required", required),
      },
      password: {
        required: helpers.withMessage("required", required),
      },
    };
  },
  mixins: [ui],
  methods: {
    ...mapActions("auth", {
      authLogin: "loginUser",
      authGetProfile: "getProfile",
    }),
    ...mapActions('game', ['launchMamahot','exitMamahot']),
    async tryToLogIn() {
      this.submitted = true;
      this.v$.$touch();
      if (this.v$.$invalid || this.auth_limit) {
        return;
      } else {
        var fd = new FormData();
        fd.append("username", this.username);
        fd.append("password", this.password);

        this.loading = true;
        const res = await this.authLogin(fd);
        if (res.success) {
          // await this.authGetProfile();
          this.closePopup("openLoginModal");
          this.reset();
          Swal.fire({
            icon: "success",
            title: this.$t("successfully logged in"),
            timer: 2000,
          });
          await this.exitMamahot();
          // this.$router.replace({ path: '/' }).catch(() => {});
        } else {
          Swal.fire({
            icon: "error",
            title: res.data.message,
            confirmButtonText: this.$t("OK"),
          });
          // this.loading = false;
          // this.error_message = res.message;
          // const expirationDate = new Date();
          // expirationDate.setTime(expirationDate.getTime() + 3 * 60 * 1000)
          // this.auth_attempts.count += 1;
          // this.auth_attempts.expiration = expirationDate;
          // $cookies.set('auth_attempts', {
          //     count: this.auth_attempts.count,
          //     expiration: expirationDate
          // }, expirationDate);
        }
      }
    },
    logoChange(){
      if(this.site == 'https://seven333.com/'){
        return require("../assets/img/logos/seven333-2.png");
      }
      else{
        return require("../assets/img/dream.png");
      }
    },
    togglePassword() {
      this.showPassword = !this.showPassword;
    },
    reset() {
      this.username = null;
      this.password = null;
      this.submitted = false;
    },
  },
};
</script>
