<template>
  <footer class="footer-section w-ba">
    <div class="container mx-auto">
    <div class="row ">
        <div class="provider-logo dflex-ac-jc">
            <img src="@/assets/img/ag.png" alt="AG Logo">
            <img src="@/assets/img/microgaming-img.png" alt="MicroGaming Logo">
            <img src="@/assets/img/evolution.png" alt="Evolution Logo">
            <img src="@/assets/img/pragmatic.png" alt="Pragmatic Play Logo">
            <img src="@/assets/img/oriental-game.png" alt="Oriental Logo">
            <img src="@/assets/img/sexygaming.png" alt="Sexy Gaming Logo">
            <img src="@/assets/img/dreamgaming.png" alt="Dream Gaming Logo">
            <img src="@/assets/img/cq9-game.png" alt="CQ9 Logo">
            <img src="@/assets/img/bng-game.png" alt="BNG Logo">
            <img src="@/assets/img/habanero-game.png" alt="Habanero Logo">
            <img src="@/assets/img/bgaming.png" alt="BGaming Logo">
            <img src="@/assets/img/stargame.png" alt="Stargame Logo">
            <img src="@/assets/img/evoplay.png" alt="Evoplay Logo">
            <img src="@/assets/img/aristocrat.png" alt="Aristocrat Logo">
            <img src="@/assets/img/playngo.png" alt="PlaynGo Logo">
            <img src="@/assets/img/genesis.png" alt="Genesis Logo">
        </div>
    </div>
    <div class="copyright w-ba flex justify-center flex-col items-center">
     <img :src="logoChange()">
        <p class="">&copy; COPYRIGHT SEVEN333 2024. ALL RIGHTS RESERVED.</p>
    </div>
    </div>

  </footer>
</template>

<script>
export default {
  data() {
    return {
      site: process.env.VUE_APP_URL,
    };
  },
  methods: {
    logoChange(){
      if(this.site == 'https://seven333.com/'){
        return require("../assets/img/logos/seven333-1.png");
      }
      else{
        return require("../assets/img/dream.png");
      }
    }
  }
};
</script>

<style>

</style>