import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./state/store";

import { i18n } from "./i18n.js";
import VueNumberFormat from "vue-number-format";
import "../src/assets/css/tailwind.css";
import "../src/assets/css/fonts.css";
import "../src/assets/css/main.css";
import "../src/assets/css/header.css";
import "../src/assets/css/banner.css";
import "../src/assets/css/game.css";
import "../src/assets/css/board.css";
import "../src/assets/css/footer.css";
import "../src/assets/css/helpers.css";
// import "../src/assets/css/hover-link.css";
// import "../src/assets/css/sc.css";
import "../src/assets/css/popup.css";
import "../src/assets/css/dropdown.css";
// import "../src/assets/css/table.css";
import "../src/assets/css/responsive.css";
import "../src/assets/css/pagination.css";
import "../src/assets/css/swal2.css";

import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faPiggyBank,
  faVault,
  faSackDollar,
  faCommentDots,
  faBell,
  faUser,
  faUserPlus,
  faUserTie,
  faRightToBracket,
  faCaretDown,
  faCaretUp,
  faDoorOpen,
  faTrophy,
  faCrown,
  faRightFromBracket,
  faTicket,
  faLock,
  faCoins,
} from "@fortawesome/free-solid-svg-icons";
import {
    faEye,
    faEyeSlash,
  } from "@fortawesome/free-regular-svg-icons";
library.add(
  faPiggyBank,
  faVault,
  faSackDollar,
  faCommentDots,
  faBell,
  faUser,
  faUserPlus,
  faUserTie,
  faRightToBracket,
  faCaretDown,
  faCaretUp,
  faDoorOpen,
  faTrophy,
  faCrown,
  faRightFromBracket,
  faTicket,
  faLock,
  faEye,
  faEyeSlash,
  faCoins
);

import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";

const globalOptions = {
  language: "ko",
  theme: "snow",
  modules: {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image"],
      [{ color: [] }],
      [{ background: [] }],
      ["blockquote", "code-block"],
      [{ script: "sub" }, { script: "super" }],
      [{ indent: "-1" }, { indent: "+1" }],
      [{ direction: "rtl" }],
      [{ size: ["small", false, "large", "huge"] }],
      [{ header: 1 }, { header: 2 }, "blockquote", "code-block"],
      [{ font: [] }],
      [{ align: [] }],
      ["clean"],
    ],
  },
};
QuillEditor.props.globalOptions.default = () => globalOptions;
createApp(App)
  .use(store)
  .use(router)
  .use(i18n)
  .use(VueNumberFormat)
  .component("QuillEditor", QuillEditor)
  .component("font-awesome-icon", FontAwesomeIcon)
  .mount("#app");
