<template>
  <div class="modal" id="profilePopUp">
    <div class="modal-content modal-panel event-notice relative">
      <div class="modal-header">
        <button class="modal-close-btn w-ba" @click="close"></button>
        <div class="modal-banner flex flex-col justify-center items-center">
          <a href="javascript:void(0)" class="modal-logo flex justify-center">
            <img src="@/assets/img/dream.png" alt="Dream Logo" />
          </a>
        </div>

        <div class="realtime-nav dflex-ac-jc p-2">
          <button
            id="my-info-btn"
            @click="setActiveTab('my-information')"
            :class="active == 'my-information' ? 'active-btn' : ''"
          >
            <font-awesome-icon
              class="text-lg lg:text-md header-icon mr-1 mr-2"
              icon="fa-solid fa-user-tie"
            />
            {{ $t("my information") }}
          </button>
          <button
            id="notice-btn"
            @click="setActiveTab('security-settings')"
            :class="active == 'security-settings' ? 'active-btn' : ''"
          >
            <font-awesome-icon
              class="text-lg lg:text-md header-icon mr-1 mr-2"
              icon="fa-solid fa-lock"
            />
            {{ $t("security settings") }}
          </button>
        </div>
      </div>
      <div class="modal-body mt-5">
        <div class="content w-ba pt-5">
          <div
            id="my-info-section"
            v-if="active == 'my-information'"
            style="overflow: hidden; position: relative"
          >
            <div v-if="loading">{{ $t("loading") }}</div>
            <div class="board-panel my-info" v-if="!loading">
              <table width="100%">
                <colgroup>
                  <col width="50%" />
                  <col width="50%" />
                </colgroup>

                <tbody>
                  <tr>
                    <td class="text-center" style="color: yellow">
                      {{ $t("username") }}
                    </td>

                    <td class="text-center">
                      {{ profile.username }}
                    </td>
                  </tr>
                  <tr>
                    <td class="text-center" style="color: yellow">
                      {{ $t("balance") }}
                    </td>

                    <td class="text-center">
                      {{ numberString(Math.floor(profile.balance)) }}
                    </td>
                  </tr>
                  <tr>
                    <td class="text-center" style="color: yellow">
                      {{ $t("points") }}
                    </td>

                    <td class="text-center">
                      {{ numberString(Math.floor(profile.points)) }}
                    </td>
                  </tr>
                  <tr>
                    <td class="text-center" style="color: yellow">
                      {{ $t("last login at") }}
                    </td>

                    <td class="text-center">
                      {{ profile.last_login_at }}
                    </td>
                  </tr>
                  <tr>
                    <td class="text-center" style="color: yellow">
                      {{ $t("last login IP") }}
                    </td>

                    <td class="text-center">
                      {{ profile.last_login_ip }}
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="mt-2">
                <!-- <Pagination
                    :data="eventData"
                    @emitPage="initList"
                    class="w-full"
                  /> -->
              </div>
            </div>
          </div>
          <div v-if="active == 'security-settings'">
            <form action="" @submit.prevent="update">
              <h3 class="font-bold">{{ $t("change password") }}</h3>
              <div class="form-group">
                <label for="current_password" class="py-2 block">{{
                  $t("current password")
                }}</label>
                <div class="flex relative">
                  <input
                    type="password"
                    v-model="current_password"
                    id="current_password"
                    v-if="!showCurrentPassword"
                  />
                  <input
                    type="text"
                    v-model="current_password"
                    v-if="showCurrentPassword"
                  />
                  <font-awesome-icon
                    class="absolute right-2 top-4"
                    :icon="
                      !showCurrentPassword
                        ? 'fa-regular fa-eye'
                        : 'fa-regular fa-eye-slash'
                    "
                    @click="toggleCurrentPassword"
                  ></font-awesome-icon>
                </div>
              </div>
              <div class="form-group">
                <label for="new_password" class="py-2 block">{{
                  $t("new password")
                }}</label>
                <div class="flex relative">
                  <input
                    type="password"
                    v-model="new_password"
                    id="new_password"
                    v-if="!showNewPassword"
                  />
                  <input
                    type="text"
                    v-model="new_password"
                    v-if="showNewPassword"
                  />
                  <font-awesome-icon
                    class="absolute right-2 top-4"
                    :icon="
                      !showNewPassword
                        ? 'fa-regular fa-eye'
                        : 'fa-regular fa-eye-slash'
                    "
                    @click="toggleNewPassword"
                  ></font-awesome-icon>
                </div>
              </div>
              <div class="form-group">
                <label for="confirm_password" class="py-2 block">{{
                  $t("confirm password")
                }}</label>
                <div class="flex relative">
                  <input
                    type="password"
                    v-model="confirm_password"
                    id="confirm_password"
                    v-if="!showConfirmPassword"
                  />
                  <input
                    type="text"
                    v-model="confirm_password"
                    v-if="showConfirmPassword"
                  />
                  <font-awesome-icon
                    class="absolute right-2 top-4"
                    :icon="
                      !showConfirmPassword
                        ? 'fa-regular fa-eye'
                        : 'fa-regular fa-eye-slash'
                    "
                    @click="toggleConfirmPassword"
                  ></font-awesome-icon>
                </div>
              </div>
              <div class="form-footer dflex-ac-jc">
                <button type="button" class="btn-yellow" @click="update">
                  {{ $t("submit") }}
                </button>
              </div>
            </form>
            <form action="" @submit.prevent="update" class="mt-5">
              <h3 class="font-bold">{{ $t("change pin") }}</h3>
              <div class="form-group">
                <label for="current_pin" class="py-2 block">{{
                  $t("current pin")
                }}</label>
                <input
                  name="pincode"
                  type="password"
                  inputmode="numeric"
                  maxlength="4"
                  v-model="current_pin"
                  id="current_pin"
                />
              </div>
              <div class="form-group">
                <label for="new_pin" class="py-2 block">{{
                  $t("new pin")
                }}</label>
                <input
                  name="pincode"
                  type="password"
                  inputmode="numeric"
                  maxlength="4"
                  v-model="new_pin"
                  id="new_pin"
                />
              </div>
              <div class="form-group">
                <label for="confirm_pin" class="py-2 block">{{
                  $t("confirm pin")
                }}</label>
                <input
                  name="pincode"
                  type="password"
                  inputmode="numeric"
                  maxlength="4"
                  v-model="confirm_pin"
                  id="confirm_pin"
                />
              </div>
              <div class="form-footer dflex-ac-jc">
                <button type="button" class="btn-yellow" @click="update">
                  {{ $t("submit") }}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Layout from "@/components/Layout";
import Pagination from "@/components/Pagination";
import ui from "@/mixins/ui";
import datetime from "@/mixins/datetime";
import number from "@/mixins/number";
import { mapActions, mapGetters } from "vuex";
import Swal from "sweetalert2";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";

export default {
  name: "Announcement",
  components: {
    Layout,
    Pagination,
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  validations() {
    return {
      current_password: "",
      new_password: "",
      confirm_password: "",
    };
  },
  mixins: [ui, datetime, number],
  data() {
    return {
      current_password: null,
      new_password: null,
      confirm_password: null,
      current_pin: null,
      new_pin: null,
      confirm_pin: null,
      showCurrentPassword: false,
      showNewPassword: false,
      showConfirmPassword: false,
      //   loading: false,
      active: "my-information",
    };
  },
  computed: {
    ...mapGetters("auth", ["authenticated", "profile"]),
  },
  props: {
    loading: {
      type: Boolean,
    },
  },
  methods: {
    ...mapActions("auth", {
      authGetProfile: "getProfile",
      authUpdateProfile: "updateProfile",
    }),
    setActiveTab(tab) {
      this.active = tab;
    },
    toggleCurrentPassword() {
      this.showCurrentPassword = !this.showCurrentPassword;
    },
    toggleNewPassword() {
      this.showNewPassword = !this.showNewPassword;
    },
    toggleConfirmPassword() {
      this.showConfirmPassword = !this.showConfirmPassword;
    },
    async update() {
      const confirmed = await Swal.fire({
        title: "Are you sure?",
        text: "This will update your profile",
        type: "warning",
        icon: "question",
        showCancelButton: true,
        padding: "2em",
      }).then((result) => {
        return result.isConfirmed;
      });
      if (confirmed) {
        let fd = new FormData();
        if (this.current_password) {
          fd.append("current_password", this.current_password);
        }
        if (this.new_password) {
          fd.append("new_password", this.new_password);
        }

        if (this.confirm_password) {
          fd.append("confirm_password", this.confirm_password);
        }
        if (this.current_pin) {
          fd.append("current_pin", this.current_pin);
        }
        if (this.new_pin) {
          fd.append("new_pin", this.new_pin);
        }

        if (this.confirm_pin) {
          fd.append("confirm_pin", this.confirm_pin);
        }
        const res = await this.authUpdateProfile(fd);
        // this.v$.$validate();
        if (res.status == 200) {
          Swal.fire({
            background: "#2a3042",
            color: "#fff",
            icon: "success",
            title: res.data.message,
          });
          this.current_password = "";
          this.new_password = "";
          this.confirm_password = "";
          this.new_pin = "";
          this.confirm_pin = "";
          this.current_pin = "";
        }
        if (res.status == 422) {
          Swal.fire("Failed!", res.data.message, "error");
          var error = res.data.message;
          console.log(error);
          //   this.error.current_password = error.current_password
          //     ? error.current_password[0]
          //     : null;
          //   this.error.new_password = error.new_password
          //     ? error.new_password[0]
          //     : null;
          //   this.error.confirm_password = error.confirm_password
          //     ? error.confirm_password[0]
          //     : null;
        }
      }
    },

    close() {
      this.closePopup("profilePopUp");
      this.reset();
    },
    reset() {
      this.current_password = "";
      this.new_password = "";
      this.confirm_password = "";
      this.new_pin = "";
      this.confirm_pin = "";
      this.current_pin = "";
    },
  },
  mounted() {

  },
};
</script>
