import $api from "../api";
import axios from "axios";


export const state = {
    exiting_game: false,
    mamahotGameProviders: [
       
        {
            "is_honorlink": false,
            "name": "BBIN 라이브",
            "title": "BBIN Live",
            "id": "BBIN Live",
            "type": "lobby",
            "logo": "vivo-icon.png",
            "game_thumbnail": "bbin.png",
            "vendor": "mamahot",
            "provider_code": "32",
            "enabled": true
        },
        {
            "is_honorlink": false,
            "name": "WM 카지노",
            "title": "WM Casino",
            "id": "WM Casino",
            "type": "lobby",
            "logo": "wmcasino-icon.png",
            "game_thumbnail": "wm.png",
            "vendor": "mamahot",
            "provider_code": "33",
            "enabled": true
        },
        {
            "is_honorlink": false,
            "name": "마이크로게이밍",
            "title": "Microgaming",
            "id": "Microgaming",
            "type": "lobby",
            "logo": "microgaming-icon.png",
            "game_thumbnail": "mg.png",
            "vendor": "mamahot",
            "provider_code": "34",
            "enabled": true
        },
        {
            "is_honorlink": false,
            "name": "드림게이밍",
            "title": "Dreamgaming",
            "type": "lobby",
            "id": "Dreamgaming",
            "logo": "dream-gaming-icon.png",
            "game_thumbnail": "dg.png",
            "vendor": "mamahot",
            "provider_code": "35",
            "enabled": true
        },
        {
            "is_honorlink": false,
            "name": "오리엔탈 게임",
            "title": "Oriental Game",
            "type": "lobby",
            "id":  "Oriental Game",
            "logo": "vivo-icon.png",
            "game_thumbnail": "og.png",
            "vendor": "mamahot",
            "provider_code": "36",
            "enabled": true
        },
        {
            "is_honorlink": false,
            "name": "아시아 유로",
            "title": "Asia Euro",
            "type": "lobby",
            "id": "Asia Euro",
            "logo": "asia-gaming-icon.png",
            "game_thumbnail": "ag.png",
            "vendor": "mamahot",
            "provider_code": "37",
            "enabled": true
        },
        // {
        //     "is_honorlink": false,
        //     "name": "Provider 38",
        //     "title": "Casino",
        //     "type": "lobby",
        //     "id": "evolution_game_shows",
        //     "logo": "vivo-icon.png",
        //     "game_thumbnail": "bbin.png",
        //     "vendor": "mamahot",
        //     "provider_code": "38",
        //     "enabled": true
        // },
        {
            "is_honorlink": false,
            "name": "아시아 카가얀",
            "title": "Asia Cagayan",
            "type": "lobby",
            "id": "Asia Cagayan",
            "logo": "asia-gaming-icon.png",
            "game_thumbnail": "ag.png",
            "vendor": "mamahot",
            "provider_code": "39",
            "enabled": true
        },
        {
            "is_honorlink": false,
            "name": "아시아 아시아",
            "title": "Asia Asia",
            "type": "lobby",
            "id":  "Asia Asia",
            "logo": "asia-gaming-icon.png",
            "game_thumbnail": "ag.png",
            "vendor": "mamahot",
            "provider_code": "40",
            "enabled": true
        },
        {
            "is_honorlink": false,
            "name": "BBIN 아시아",
            "title": "BBIN Asia",
            "type": "lobby",
            "id": "BBIN Asia",
            "logo": "vivo-icon.png",
            "game_thumbnail": "bbin_asia.png",
            "vendor": "mamahot",
            "provider_code": "41",
            "enabled": true
        },
        {   
            "is_honorlink": true,
            "title": "Evolution",
            "type": "lobby",
            "id": "evolution_game_shows",
            "provider": "QX",
            "name": "에볼루션",
            "logo": "evolution-gaming-icon.png",
            "game_thumbnail": "evolution.png",
            "vendor": "evolution",
            "enabled": true
        },
        // {
        //     "is_honorlink": true,
        //     "title": "Pragmatic Play",
        //     "type": "lobby",
        //     "id": "101",
        //     "provider": "CX",
        //     "name": "프라그매틱 라이브",
        //     "logo": "pragmatic-play-icon.png",
        //     "game_thumbnail": "pp.png",
        //     "vendor": "PragmaticPlay Live"
        // },
        // {
        //     "is_honorlink": true,
        //     "title": "TG/PD",
        //     "type": "lobby",
        //     "id": "bota",
        //     "provider": "",
        //     "name": "TG SPEED",
        //     "logo": "bota-icon.png",
        //     "game_thumbnail": "tgspeed.png",
        //     "vendor": "bota"
        // },
        // {
        //     "is_honorlink": true,
        //     "title": "Betgames",
        //     "type": "lobby",
        //     "id": "betgame_casino",
        //     "provider": "",
        //     "name": "빅게이밍",
        //     "logo": "betgame-icon.png",
        //     "game_thumbnail": "betgames.png",
        //     "vendor": "Betgames.tv"
        // },
        // {
        //     "is_honorlink": true,
        //     "title": "Skywind Live",
        //     "type": "lobby",
        //     "id": "skywind_casino",
        //     "name": "스카이윈드",
        //     "logo": "skywind-icon.png",
        //     "game_thumbnail": "skywind.jpg",
        //     "vendor": "Skywind Live"
        // }
    ],
    honorLinkSlot: [
        {
            "title": "PRAGMATIC",
            "type": "lobby",
            "id": "pragmatic_play_slots",
            "name": "프라그매틱 플레이",
            "game_thumbnail": "pragmaticplay.jpg",
            "thumbnail": "https://ap-south-1.linodeobjects.com/cdn.honorlink.org/pragmaticplay/lobby.jpg",
            "thumbnails": {
                "300x300": "https://ap-south-1.linodeobjects.com/cdn.honorlink.org/pragmaticplay/lobby.jpg"
            },
            "logo": "vivo-icon.png",
            "vendor": "PragmaticPlay"
        },
        {
            "title": "Booongo Slots Lobby",
            "type": "lobby",
            "id": "booongo_slots",
            "provider": "BNG",
            "name": "부운고",
            "game_thumbnail": "booongo1.jpg",
            "thumbnail": "https://ap-south-1.linodeobjects.com/cdn.honorlink.org/booongo/lobby.png",
            "thumbnails": {
                "300x300": "https://ap-south-1.linodeobjects.com/cdn.honorlink.org/booongo/lobby.png"
            },
            "logo": "vivo-icon.png",
            "vendor": "Booongo"
        },
        
        // {
        //     "title": "HABANERO",
        //     "type": "lobby",
        //     "id": "habanero_slots",
        //     "name": "하바네로",
        //     "game_thumbnail": "habanero.jpg",
        //     "thumbnail": "https://casinodaddy.com/wp-content/uploads/2020/04/habanero-logo.png",
        //     "thumbnails": {
        //         "300x300": "https://casinodaddy.com/wp-content/uploads/2020/04/habanero-logo.png"
        //     },
        //     "logo": "vivo-icon.png",
        //     "vendor": "Habanero"
        // },
        {
            "title": "Microgaming",
            "type": "lobby",
            "id": "microgaming_slots",
            "name": "마이크로게이밍",
            "game_thumbnail": "microgaming.jpg",
            "thumbnail": "https://casinodaddy.com/wp-content/uploads/2020/04/habanero-logo.png",
            "thumbnails": {
                "300x300": "https://casinodaddy.com/wp-content/uploads/2020/04/habanero-logo.png"
            },
            "logo": "vivo-icon.png",
            "vendor": "MicroGaming Plus Slo"
        },
        {
            "title": "Dreamtech",
            "type": "lobby",
            "id": "dreamtech_slots",
            "provider": "BNG",
            "name": "드림테크게이밍",
            "game_thumbnail": "dreamtech.jpg",
            "thumbnail": "https://ap-south-1.linodeobjects.com/cdn.honorlink.org/booongo/lobby.png",
            "thumbnails": {
                "300x300": "https://ap-south-1.linodeobjects.com/cdn.honorlink.org/booongo/lobby.png"
            },
            "logo": "vivo-icon.png",
            "vendor": "dreamtech"
        },
        {
            "title": "CQ9",
            "type": "lobby",
            "id": "cq9_slots",
            "name": "씨큐",
            "game_thumbnail": "CQ9.jpg",
            "thumbnail": "https://ap-south-1.linodeobjects.com/cdn.honorlink.org/cq9/lobby.png",
            "thumbnails": {
                "300x300": "https://ap-south-1.linodeobjects.com/cdn.honorlink.org/cq9/lobby.png"
            },
            "logo": "vivo-icon.png",
            "vendor": "CQ9"
        },
        {
            "title": "NETENT",
            "type": "lobby",
            "id": "netent_slots",
            "name": "넷앤트",
            "game_thumbnail": "netent.jpg",
            "thumbnail": "https://ap-south-1.linodeobjects.com/cdn.honorlink.org/netent/lobby.png",
            "thumbnails": {
                "300x300": "https://ap-south-1.linodeobjects.com/cdn.honorlink.org/netent/lobby.png"
            },
            "logo": "vivo-icon.png",
            "vendor": "netent"
        },
        {
            "title": "EVOPLAY",
            "type": "lobby",
            "id": "evoplay_slots",
            "name": "에보플레이",
            "game_thumbnail": "EVO.jpg",
            "thumbnails": {
                "300x300": "https://ap-south-1.linodeobjects.com/cdn.honorlink.org/playson/lobby.png"
            },
            "logo": "vivo-icon.png",
            "vendor": "evoplay"
        },
        {
            "title": "PG Soft",
            "type": "lobby",
            "id": "pgsoft_slots",
            "name": "포켓게임소프트",
            "game_thumbnail": "pgsoft.jpg",
            "thumbnails": {
                "300x300": "https://ap-south-1.linodeobjects.com/cdn.honorlink.org/playson/lobby.png"
            },
            "logo": "vivo-icon.png",
            "vendor": "PG Soft"
        },
        {
            "title": "Elk Studios",
            "type": "lobby",
            "id": "elkstudios",
            "name": "이엘케이",
            "game_thumbnail": "elk.jpg",
            "thumbnails": {
                "300x300": "https://ap-south-1.linodeobjects.com/cdn.honorlink.org/playson/lobby.png"
            },
            "logo": "vivo-icon.png",
            "vendor": "Elk Studios"
        },
        {
            "title": "Playson",
            "type": "lobby",
            "id": "playson_slots",
            "name": "플레이슨",
            "game_thumbnail": "playson.png",
            "thumbnail": "https://ap-south-1.linodeobjects.com/cdn.honorlink.org/playson/lobby.png",
            "thumbnails": {
                "300x300": "https://ap-south-1.linodeobjects.com/cdn.honorlink.org/playson/lobby.png"
            },
            "logo": "vivo-icon.png",
            "vendor": "PlaySon"
        },
        {
            "title": "Blueprint Gaming",
            "type": "lobby",
            "id": "blueprint_slots",
            "name": "블루프린트게이밍",
            "game_thumbnail": "blueprint.jpg",
            "logo": "vivo-icon.png",
            "vendor": "Blueprint Gaming"
        },
        {
            "title": "Relax Gaming",
            "type": "lobby",
            "id": "relaxgaming_slots",
            "name": "릴렉스게이밍",
            "game_thumbnail": "relaxgaming.jpg",
            "logo": "vivo-icon.png",
            "vendor": "Relax Gaming"
        },
        {
            "title": "spinomenal",
            "type": "lobby",
            "id": "spinomenal_slots",
            "name": "스피노미날",
            "game_thumbnail": "spinomenal.jpg",
            "logo": "vivo-icon.png",
            "vendor": "spinomenal"
        },
        {
            "title": "Red Tiger",
            "type": "lobby",
            "id": "redtiger_slots",
            "name": "레드타이거",
            "game_thumbnail": "redtiger.jpg",
            "thumbnail": "https://ap-south-1.linodeobjects.com/cdn.honorlink.org/redtiger/lobby.png",
            "thumbnails": {
                "300x300": "https://ap-south-1.linodeobjects.com/cdn.honorlink.org/redtiger/lobby.png"
            },
            "logo": "vivo-icon.png",
            "vendor": "redtiger"
        },
        {
            "title": "Nolimit City",
            "type": "lobby",
            "id": "nolimitcity_slots",
            "name": "노리밋시티",
            "game_thumbnail": "nolimit.jpg",
            "thumbnail": "https://ap-south-1.linodeobjects.com/cdn.honorlink.org/nolimitcity/lobby.png",
            "thumbnails": {
                "300x300": "https://ap-south-1.linodeobjects.com/cdn.honorlink.org/nolimitcity/lobby.png"
            },
            "logo": "vivo-icon.png",
            "vendor": "Nolimit City"
        },
        {
            "title": "Wazdan",
            "type": "lobby",
            "id": "wazdan_slots",
            "name": "와즈단",
            "game_thumbnail": "wazdan.png",
            "thumbnail": "https://igamingbusiness.com/wp-content/uploads/2020/08/Wazdan-logo-with-tagline_0.jpg",
            "thumbnails": {
                "300x300": "https://igamingbusiness.com/wp-content/uploads/2020/08/Wazdan-logo-with-tagline_0.jpg"
            },
            "logo": "vivo-icon.png",
            "vendor": "Wazdan"
        },
        {
            "title": "PlayStar",
            "type": "lobby",
            "id": "playstar_slots",
            "name": "플레이스타",
            "game_thumbnail": "playstar.jpg",
            "thumbnail": "https://betandslots.com/wp-content/uploads/2020/05/playstar.jpg",
            "thumbnails": {
                "300x300": "https://betandslots.com/wp-content/uploads/2020/05/playstar.jpg"
            },
            "logo": "vivo-icon.png",
            "vendor": "PlayStar"
        },
        {
            "title": "GameArt",
            "type": "lobby",
            "id": "gameart_slots",
            "name": "게임아트",
            "game_thumbnail": "gameart.jpg",
            "logo": "vivo-icon.png",
            "vendor": "GameArt"
        },
        {
            "title": "Thunderkick",
            "type": "lobby",
            "id": "thunderkick_slots",
            "name": "썬더킥",
            "game_thumbnail": "thunderkick.png",
            "thumbnail": "https://www.thunderkick.com/wp-content/uploads/2021/04/LOGO_horizontal_black-768x768.jpg",
            "thumbnails": {
                "300x300": "https://www.thunderkick.com/wp-content/uploads/2021/04/LOGO_horizontal_black-768x768.jpg"
            },
            "logo": "vivo-icon.png",
            "vendor": "Thunderkick"
        },
        {
            "title": "Play pearls",
            "type": "lobby",
            "id": "pearls_slots",
            "name": "플레이펄즈",
            "game_thumbnail": "playpearls.png",
            "thumbnail": "https://www.top10onlinecasino.site/wp-content/uploads/2019/06/PlayPearls.png",
            "thumbnails": {
                "300x300": "https://www.top10onlinecasino.site/wp-content/uploads/2019/06/PlayPearls.png"
            },
            "logo": "vivo-icon.png",
            "vendor": "PlayPearls"
        },

        {
            "title": "Mobilots",
            "type": "lobby",
            "id": "mobilots_slots",
            "name": "모빌리엇츠",
            "game_thumbnail": "mobilots.png",
            "thumbnail": "https://assets.vegasslotsonline.com/vegasslotsonline.com/assets/images/software/mobilots.png",
            "thumbnails": {
                "300x300": "https://assets.vegasslotsonline.com/vegasslotsonline.com/assets/images/software/mobilots.png"
            },
            "logo": "vivo-icon.png",
            "vendor": "Mobilots"
        },
        {
            "title": "Dragoon Soft",
            "type": "lobby",
            "id": "dragoon_slots",
            "name": "드라군소프트",
            "game_thumbnail": "dragoonsoft.png",
            "thumbnail": "https://dragoonsoftslot.com/wp-content/uploads/2022/04/dragoon-soft-slot-%E0%B9%80%E0%B8%A7%E0%B9%87%E0%B8%9A%E0%B8%95%E0%B8%A3%E0%B8%87_03.png",
            "thumbnails": {
                "300x300": "https://dragoonsoftslot.com/wp-content/uploads/2022/04/dragoon-soft-slot-%E0%B9%80%E0%B8%A7%E0%B9%87%E0%B8%9A%E0%B8%95%E0%B8%A3%E0%B8%87_03.png"
            },
            "logo": "vivo-icon.png",
            "vendor": "Dragoon Soft"
        },
        {
            "title": "Triple Profit Gaming",
            "type": "lobby",
            "id": "tripleprofitgaming_slots",
            "name": "트리플프로핏",
            "game_thumbnail": "tp.png",
            "logo": "vivo-icon.png",
            "vendor": "Triple Profit Gaming"
        },
        {
            "title": "1X2 Gaming",
            "type": "lobby",
            "id": "onextwogaming_slots",
            "name": "1X2게이밍",
            "game_thumbnail": "12.png",
            "logo": "vivo-icon.png",
            "vendor": "1X2 Gaming"
        },
        {
            "title": "BF Games",
            "type": "lobby",
            "id": "bfgames_slots",
            "name": "BF게임즈",
            "game_thumbnail": "bfgs.png",
            "logo": "vivo-icon.png",
            "vendor": "bfgames"
        },
        {
            "title": "Endorphina",
            "type": "lobby",
            "id": "endorphina_slots",
            "name": "엔도르피나",
            "game_thumbnail": "endorphina.png",
            "logo": "vivo-icon.png",
            "vendor": "endorphina"
        },
        {
            "title": "Platipus",
            "type": "lobby",
            "id": "platipus_slots",
            "name": "플래티퍼스",
            "game_thumbnail": "platipuss.png",
            "logo": "vivo-icon.png",
            "vendor": "platipus"
        },
        {
            "title": "Retrogames",
            "type": "lobby",
            "id": "retrogames_slots",
            "name": "레트로게임즈",
            "game_thumbnail": "retrogames.png",
            "logo": "vivo-icon.png",
            "vendor": "retrogames"
        },
        {
            "title": "fils",
            "type": "lobby",
            "id": "fils_slots",
            "name": "필스",
            "game_thumbnail": "fils.png",
            "logo": "vivo-icon.png",
            "vendor": "fils"
        },
        {
            "title": "platingaming",
            "type": "lobby",
            "id": "platingaming_slots",
            "name": "플레이팅게이밍",
            "game_thumbnail": "platingaming.png",
            "logo": "vivo-icon.png",
            "vendor": "platingaming"
        },
        {
            "title": "eagaming",
            "type": "lobby",
            "id": "eagaming_slots",
            "name": "EA게이밍",
            "game_thumbnail": "eagaming.png",
            "logo": "vivo-icon.png",
            "vendor": "eagaming"
        }
    ],
}
export const actions = {
    honorlinkSlotGames({ commit, rootGetters, dispatch }, pl) {
        return new Promise(function (resolve) {
            axios
                .get(`/data/updated-${pl.type}-gamelist/${pl.game_id}.json`, {
                    headers: {
                        Authorization: rootGetters["auth/bearer_token"],
                    },
                })
                .then(function (res) {
                    if (res.status == 200) {
                        resolve(res);
                    }
                })
                .catch(function (err) {
                    console.log(err, "err");
                    if (err.response.status == 401) {
                        dispatch("auth/logout", {}, { root: true }).then(() => { });
                    }
                });
        });
    },
    launchHonorlink({ commit, rootGetters, dispatch }, pl) {
        return new Promise(function (resolve) {
            // commit('triggerLaunching', true, { root: true })
            $api
                .get(`${rootGetters["auth/role"]}/game-launch?game_id=${pl.game_id}&vendor=${pl.vendor}&language=${pl.lang}`, {
                    headers: {
                        Authorization: rootGetters["auth/bearer_token"],
                    },
                })
                .then(function (res) {
                    if (res.status == 200) {
                        // console.log("gamelaunch", res);
                        // commit("setGameLaunch", res.data.data);
                        resolve(res);
                        // commit('triggerLaunching', false, { root: true })
                    }
                })
                .catch(function (err) {
                    // console.log(err, "err");
                    if (err.response.status == 401) {
                        resolve(err.response);
                        //   dispatch("auth/logout", {}, { root: true }).then(() => {});

                    }
                    if (err.response.status == 422) {
                        console.log(err);
                        resolve(err.response);
                    }
                    // commit('triggerLaunching', false, { root: true })
                });
        });
    },
    launchMamahot({ commit, rootGetters, dispatch }, pl) {
        return new Promise(function (resolve) {
            $api
                .post(`${rootGetters["auth/role"]}/mamahot/game-launch`, pl, {
                    headers: {
                        Authorization: rootGetters["auth/bearer_token"],
                    },
                })
                .then(function (res) {
                    if (res.status == 200) {
                        resolve(res);
                    }
                })
                .catch(function (err) {
                    resolve(err.response);
                });
        });
    },
    exitMamahot({ commit, rootGetters, dispatch }, pl) {
        return new Promise(function (resolve) {
            $api
                .post(`${rootGetters["auth/role"]}/mamahot/exit-game`, pl, {
                    headers: {
                        Authorization: rootGetters["auth/bearer_token"],
                    },
                })
                .then(function (res) {
                    if (res.status == 200) {
                        resolve(res);
                    }
                })
                .catch(function (err) {
                    resolve(err.response);
                });
        });
    },
}

export const mutations = {
    triggerExiting(state, exiting) {
        state.exiting_game = exiting;
    },
};