<template>
<!-- <div class="wrapper">  -->
 <section class="banner-section w-ba">
    <img src="@/assets/img/aria.png" alt="" class="banner-bg" />
    <div class="container mx-auto relative dflex-ae-jc h-full">
      <div class="center w-full flex flex-col justify-center items-center">
        <a href="#" class="logo">
          <img :src="logoChange()" alt="" class="logo-bg" />
          <img :src="logoChange()" alt="" class="logo-img" />
        </a>
        <div class="banner-carousel carousel dflex-ac-jc align-content-center w-ba pointer-event">
          <div class="carousel-inner">
            <ol class="carousel-indicators dflex-ac-jc" >
              <li data-target=".banner-carousel" data-slide-to="0" class="active"> </li>
              <li data-target=".banner-carousel" data-slide-to="1" > </li>
              <li data-target=".banner-carousel" data-slide-to="2" > </li>
            </ol>
          </div>
        </div>
      </div>
  	<div class="banner-background relative">
			<div class="light">
				<img src="@/assets/img/light.png">
			</div>
			<div class="glow-left">
				<img src="@/assets/img/glow-left.png">
			</div>
			<img class="casino-items" src="@/assets/img/casino-items.png">
			<img class="girl-left" src="@/assets/img/girl-left.png">
			<img class="girl-right" src="@/assets/img/girl-right.png">
			<img class="girl-center" src="@/assets/img/girl-center.png">
			<div class="glow-right">
				<img src="@/assets/img/glow-right.png">
			</div>
			<img class="candy-slot" src="@/assets/img/candy-slot.png">
			<img class="money-slot" src="@/assets/img/money-slot.png">
		</div>
    </div>
  </section>
<!-- </div> -->


</template>
<script>
export default {
  data() {
    return {
      site: process.env.VUE_APP_URL,
    };
  },
  methods: {
    logoChange(){
      if(this.site == 'https://seven333.com/'){
        return require("../assets/img/logos/seven333-2.png");
      }
      else{
        return require("../assets/img/dream.png");
      }
    }
  }
};
</script>